import React, { useState } from "react";
import submenuservicon from "../../../assets/imgs/serv-icons/1.png";
import SideNavigation from "../../Menu-sidenavbar/SideNavigation ";
import { useLocation } from "react-router-dom";
import HeaderBackground from "../../../common/SubpagesBGimage";

function PsgLap() {
  const [activeTab, setActiveTab] = useState("tabs-1");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openKeys, setOpenKeys] = useState([]);
  const location = useLocation();

  const routeNames = {
    "/about-us/psg-iap": { menuName: "About Us", subMenuName: "PSG Iap" },
  };

  const { menuName, subMenuName } =
    location.state || routeNames[location.pathname] || {};

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const onOpenChange = (keys) => {
    setOpenKeys(keys);
  };

  const introContent = [
    
      <p>

<p> PSGIAP is a shining jewel in the academic legacy of PSG and Sons’ Charities, established in 2021 with the motto of "INFINITE INGENUITY." At PSGIAP, we prioritize the holistic development of our students, blending value-based education with strong ethical foundations. Our mission is to nurture future architects, practitioners, and professionals who embody a commitment to lifelong learning and a responsibility to serve society as agents of positive change. We aim to equip our students with the skills and mind-set needed to address diverse human and social needs.
  </p>  
  <br/>
  <p>
  Currently, the institute offers a five-year full-time undergraduate degree program in Bachelor of Architecture (B.Arch), affiliated to Anna University, Chennai, and approved by the Council of Architecture, New Delhi.
  </p>
  <br/>
  <p>
  The teaching-learning process at PSGIAP is highly innovative, incorporating interactive presentations and collaborative discussions to enhance the curriculum and provide an enriching academic experience. Nestled in a serene and picturesque campus, our institute offers a heart-warming and inspiring atmosphere, creating the perfect environment for academic growth and creative exploration.
  </p>

     </p>
 
   
  ]

  const sections = [
    {
      section: {
        section_id: 1,
        section_name: "Our Mission",
      },
      contents: [
        {
          section_content: (
            <>
              <>
              “To provide intellectually stimulating environment, where
                students find their creative identities and emerge {" "}
                <b style={{color:"#002244", fontStyle:"italic"}}>
                with an interdisciplinary education in Art, Architecture, Planning,
                  Design and Technology
                </b>{" "}
                along with a deep-rooted knowledge of one’s own surroundings,
                social awareness, and grounding in cultural heritage, resulting in ethical, responsible professionals.”
              </>
            </>
          ),
        },
        {
          section_content: (
            <>
            <b style={{color:"black"}}> To provide an enriching and experiential learning environment of
            the highest quality and integrity PSG IAP,</b>
             
    
               <b style={{color:"#002244", fontStyle:"bold",fontWeight:"700" ,fontStyle:"italic"}} > VALUES</b>
            </>
          ),
        },
        {
          section_content: (
            <ul className="custom-bullet-list">
              <li>Quality teaching and learning process.</li>
              <li>
                Opportunities for individuals to realize their full potential.
              </li>
              <li>The rigor, joy, and fulfillment of intellectual discovery.</li>
              <li>Supportive and congenial relationships.</li>
              <li>Respect for diversity and inclusivity.</li>
              <li>Service to society.</li>
            </ul>
          ),
        },
      ],
    },
    {
      section: {
        section_id: 2,
        section_name: "Our Vision",
      },
      contents: [
        {
          section_content:(
            <>
            “To achieve academic excellence in Architecture and Planning through{" "}
            <b style={{ color: "#002244",fontStyle:"italic" }}>
            innovating, creating, acquiring and disseminating knowledge using sustainable local and global practices and enhancing the quality of society through responsible built environment.”
            </b>
          </>
          
         
          )
        },
      ],
    },
    // {
    //   section: {
    //     section_id: 3,
    //     section_name: "Coal",
    //   },
    //   contents: [
    //     {
    //       section_content:
    //         "A pedagogy-centred curriculum sets out to produce students who are consistently creative selves, lifetime learners with an independent critical and artistic agency rooted in the essence of what it is to be human, whose consciousness participates constructively in the world, whose agency and commitment remain unaffected by superficial changes of context. The challenge is captured in a statement by Richard Shaull (which draws from the philosophy of Paolo Freire)",
    //     },
    //     {
    //       section_content:
    //         "“There is no such thing as a neutral education process. Education either functions as an instrument which is used to facilitate the integration of generations into the logic of the present system and bring about conformity to it, or it becomes the ‘practice of freedom’, the means by which men and women deal critically with reality and discover how to participate in the transformation of their world.” (Freire, 2019).",
    //     },
    //   ],
    // },
  ];

  return (
    <div id="smooth-content">
      <main className="main-bg blog-wrap">
        <HeaderBackground
          menuName={menuName}
          subMenuName={subMenuName}
          handleMenuToggle={handleMenuToggle}
        />
        <section className="services-tab section-padding mb-80 abt-uss">
          <div className="container">
            <div className="cal-action pb-10 mb-40 about-us-texts">
              <h1 className="psgiap-submenu heading-submenu-res overall-submenu-heading">PSG Institute of Architecture and Planning</h1>
            </div>
            <div className="sec-head mb-30"></div>
            <div className="text mb-50">
            {introContent.map((content, index) => (
          <p key={index} className="para-psg">
            {index >= 3 ? <li>{content}</li> : content}
          </p>
        ))}
            </div>

            <div className="row lg-marg" id="tabs">
              <div className="d-lg-none col-12">
                <div className="serv-tab-link tab-links full-width pt-40">
                  <div className="row justify-content-end">
                    <div className="col-lg-12">
                      <ul className="rest">
                        {sections.map((section, index) => (
                          <li
                            key={section.section.section_id}
                            className={`item-link mb-15 go-moreee-tabs go-more-psg-iap go-more-psg-iap-line1 ${
                              activeTab === `tabs-${index + 1}` ? "current" : ""
                            }`}
                            onClick={() => handleTabClick(`tabs-${index + 1}`)}
                          >
                            <h5>
                              <span className="fz-18 opacity-7 mr-15">{`0${
                                index + 1
                              }`}</span>
                              {section.section.section_name}
                            </h5>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 d-none d-lg-block side-txt">
                <div className="serv-tab-link tab-links full-width pt-40">
                  <div className="row justify-content-end">
                    <div className="col-lg-12 iaq-padding">
                      <ul className="rest">
                        {sections.map((section, index) => (
                          <li
                            key={section.section.section_id}
                            className={`item-link mb-15 go-moreee-tabs go-more-psg-iap go-more-psg-iap-line1 ${
                              activeTab === `tabs-${index + 1}` ? "current" : ""
                            }`}
                            onClick={() => handleTabClick(`tabs-${index + 1}`)}
                          >
                            <div className="u-line linee psg-iaq-tabs">
                              <span className="icon ti-arrow-circle-right tabss-psg "></span>
                              <span className="text fz-20 psg-margin">
                                {section.section.section_name}
                              </span>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-12">
                <div className="serv-tab-cont md-mb80 sticky-item">
                  {sections.map((section, index) => (
                    <div
                      key={section.section.section_id}
                      className={`tab-content ${
                        activeTab === `tabs-${index + 1}` ? "current" : ""
                      }`}
                      id={`tabs-${index + 1}`}
                    >
                      <div className="item">
                        <div className="sub-bg">
                          <div className="icon-img-60 mb-40">
                            <img src={submenuservicon} alt="" />
                          </div>
                          <div className="text">
                            <ul className="rest list-arrow mt-30 pt-30 bord-thin-top">
                              {section.contents.map((content, contentIndex) => (
                                <li key={contentIndex} className="pointtxt">
                                  <p className="inline fw-600 fz-15 text ml-5 mt-2">
                                    {content.section_content}
                                  </p>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <SideNavigation
        key={menuName}
        isMenuOpen={isMenuOpen}
        handleMenuToggle={handleMenuToggle}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        defaultOpenSubMenu={menuName}
      />
    </div>
  );
}

export default PsgLap;



