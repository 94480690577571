import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useLocation } from "react-router-dom";
import HeaderBackground from "../../../common/SubpagesBGimage";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import { useDispatch, useSelector } from "react-redux";
import { InfrastructureActions } from "../../../Store/Infrastructure/action";
import SideNavigation from "../../Menu-sidenavbar/SideNavigation ";

function Infrastructure() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("");
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const dispatch = useDispatch();

  const routeNames = useMemo(() => ({ "/infrastructure": { menuName: "Infrastructure" } }), []);

  useEffect(() => {
    dispatch({ type: InfrastructureActions.GET_INFRASTRUCTURE });
  }, [dispatch]);

  const { InfrastructureData, InfrastructureDetail } = useSelector(
    (state) => state.InfrastructureReducer
  );

  useEffect(() => {
    if (InfrastructureData?.data?.infrastructure_fronts?.length) {
      const firstTab = InfrastructureData.data.infrastructure_fronts[0];
      setActiveTab(`tabs-1`);
      dispatch({ type: InfrastructureActions.GET_INFRASTRUCTURE_DETAIL, payload: { id: firstTab.id } });
    }
  }, [InfrastructureData, dispatch]);

  useEffect(() => {
    if (InfrastructureDetail) {
      setLoading(false);
    }
  }, [InfrastructureDetail]);

  const { menuName } = location.state || routeNames[location.pathname] || {};

  const handleMenuToggle = useCallback(() => setIsMenuOpen((prev) => !prev), []);

  const handleTabClick = useCallback((tabId, itemId) => {
    setActiveTab(tabId);
    setLoading(true);
    dispatch({ type: InfrastructureActions.GET_INFRASTRUCTURE_DETAIL, payload: { id: itemId } });
  }, [dispatch]);

  const handleThumbnailClick = useCallback((items, title = "Image") => {
    if (!items?.length) return;
    const galleryItems = items.map((item, index) => ({
      src: item,
      opts: { caption: `${title} - Image ${index + 1}` },
    }));
    Fancybox.show(galleryItems, { infinite: true, buttons: ["zoom", "close", "fullscreen"], loop: true });
  }, []);

  const renderContent = useMemo(() => {
    if (loading) {
      return <div className="spinner-container-admission">
      <div className="spinner-admission"></div>
    </div>
    }
    if (!activeTab || !InfrastructureDetail?.infrastructure_details?.length) return null;

    return (
      <div className="row">
        {InfrastructureDetail.infrastructure_details.map((detail, index) => {
          const imageSrc = detail.list_image || detail.infrastructure_thumbnail || "default-fallback-image-url";
          return (
            <div key={index} className="col-lg-4 col-md-6 col-sm-12 mb-4">
              <div className="archi-card-container mt-30">
                <img
                  src={imageSrc}
                  alt={detail.infrastructure_title}
                  className="archi-card-image-lifeatcampus infrastructure-img"
                  onClick={() =>
                    detail.subfolders
                      ? handleThumbnailClick(detail.subfolders.map((sf) => sf.subfolder_image), detail.infrastructure_title)
                      : handleThumbnailClick([imageSrc])
                  }
                  style={{ cursor: "pointer" }}
                  loading="lazy"
                />

{/* 
TO OPEN ALL IMAGES IN FANCYBOX
<img
  src={imageSrc}
  alt={detail.infrastructure_title}
  className="archi-card-image-lifeatcampus infrastructure-img"
  onClick={() => {
    const allImages = detail.subfolders?.length
      ? detail.subfolders.map((sf) => sf.subfolder_image)
      : InfrastructureDetail.infrastructure_details.map((d) => d.list_image || d.infrastructure_thumbnail || "default-fallback-image-url");

    handleThumbnailClick(allImages, detail.infrastructure_title);
  }}
  style={{ cursor: "pointer" }}
  loading="lazy"
/> */}


                <p className="mt-2 text-center infra-subfolder-title">{detail.infrastructure_title}</p>
              </div>
            </div>
          );
        })}
      </div>
    );
  }, [activeTab, InfrastructureDetail, handleThumbnailClick, loading]);

  return (
    <div>
      <HeaderBackground menuName={menuName} handleMenuToggle={handleMenuToggle} />
      <section className="services-tab section-padding mb-80">
        <div className="container">
          <div className="cal-action pb-10 mb-40">
            <div className="bord d-flex align-items-center">
              <h1 className="fw-300 heading-submenu-res core-fac-heading-res study-tour-ress overall-submenu-heading">Infrastructure</h1>
            </div>
          </div>
          <div className="row lg-marg" id="tabs">
            {/* Small screen tabs */}
            <div className="col-lg-12 small-screen-titles">
              <ul className="rest">
                {InfrastructureData?.data?.infrastructure_fronts?.map((tab, index) => (
                  <li
                    key={index}
                    className={`item-link mb-15 go-moreee-tabs go-more-psg-iap go-more-psg-iap-line1 ${activeTab === `tabs-${index + 1}` ? "current" : ""}`}
                    onClick={() => handleTabClick(`tabs-${index + 1}`, tab.id)}
                  >
                    <h5>
                      <span className="fz-18 opacity-7 mr-15">{`0${index + 1}`}</span> {tab.title}
                    </h5>
                  </li>
                ))}
              </ul>
            </div>

            {/* Large screen tabs */}
            <div className="col-lg-4 d-none d-lg-block side-txt large-screen-titles" style={{ marginTop: "-50px" }}>
              <div className="serv-tab-link tab-links full-width pt-40">
                <ul className="rest">
                  {InfrastructureData?.data?.infrastructure_fronts?.map((tab, index) => (
                    <li
                      key={index}
                      className={`item-link mb-15 go-moreee-tabs go-more-psg-iap go-more-psg-iap-line1 ${activeTab === `tabs-${index + 1}` ? "current" : ""}`}
                      onClick={() => handleTabClick(`tabs-${index + 1}`, tab.id)}
                    >
                      <div className="u-line linee">
                        <span className="icon ti-arrow-circle-right"></span>
                        <span className="text fz-18 psg-margin">{tab.title}</span>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            {/* Content Section */}
            <div className="col-lg-8 col-12">
              <div className="md-mb80">{renderContent}</div>
            </div>
          </div>
        </div>
      </section>
      <SideNavigation key={menuName} isMenuOpen={isMenuOpen} handleMenuToggle={handleMenuToggle} />
    </div>
  );
}

export default React.memo(Infrastructure);
