import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import SideNavigation from "../Menu-sidenavbar/SideNavigation ";
import { WorkWithUsActions } from "../../Store/Work with us/action";
import { useDispatch, useSelector } from "react-redux";
import HeaderBackground from "../../common/SubpagesBGimage";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import ReCAPTCHA from "react-google-recaptcha";
import {sitekey} from "../../utils/Constant"
function WorkWithUss() {
  const dispatch = useDispatch();
  const [currentTab, setCurrentTab] = useState("tab-1");
  const [activeAccordion, setActiveAccordion] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openKeys, setOpenKeys] = useState([]);
  const location = useLocation();
  const [animationTriggered, setAnimationTriggered] = useState(false);
  const [showForm, setShowForm] = useState(false);
    const [captchaValue, setCaptchaValue] = useState(null);
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    resume: null,
  });
  const [error, setError] = useState("");

  const routeNames = {
    "/work-with-us": { menuName: "Work with us" },
  };

  const { menuName } = location.state || routeNames[location.pathname] || {};

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const onOpenChange = (keys) => {
    setOpenKeys(keys);
  };

  const handleMouseEnter = (tabId) => {
    setCurrentTab(tabId);
  };

  const handleTabClick = (index) => {
    setActiveAccordion((prevIndex) => (prevIndex === index ? null : index));
  };

  useEffect(() => {
    dispatch({ type: WorkWithUsActions.GET_WORK_WITH_US });
    setAnimationTriggered(true);
  }, [dispatch]);

  const { WorkwithusData } = useSelector((state) => state?.WorkWithUsReducer);

  // Ensure WorkwithusData and work-with-us exist
  const workWithUsArray = WorkwithusData?.["work-with-us"] || [];
  const currentTabData = workWithUsArray.find(
    (tab) => tab.id === parseInt(currentTab.split("-")[1])
  );

  const handleFormChange = (e) => {
    const { name, value, files } = e.target;

    if (name === "resume") {
      setFormValues({ ...formValues, resume: files[0] });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }

    setError(""); // Clear error when user starts typing
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const { name, email, resume } = formValues;
    let errorMessages = []; 
    if (!captchaValue) {
      errorMessages.push("Please complete the ReCAPTCHA verification.");
    }
    if (!name) {
      errorMessages.push("Full Name is required.");
    }
    if (!email) {
      errorMessages.push("Email is required.");
    }
    if (!resume) {
      errorMessages.push("Resume is required.");
    }

  
    if (errorMessages.length > 0) {
      setError(errorMessages); // Set all error messages
      return;
    }
    // console.log("Form submitted successfully:", formValues);
    setShowForm(false);
    setFormValues({ name: "", email: "", resume: ""});
    setCaptchaValue(null); // Reset Captcha
    setError(""); // Clear errors
  };

  return (
    <div className="workwithus-container">
      <HeaderBackground
        menuName={menuName}
        handleMenuToggle={handleMenuToggle}
      />
      <section className="portfolio-tab crev section-padding">
        <div className="container position-re">
          <div className="sec-head mb-80">
            <div className="bord pt-25 bord-thin-top d-flex align-items-center">
              <h4 className="fw-600 text-u ls1 pl-30">
                Work with us <span className="fw-200"></span>
              </h4>
            </div>
            <div className="program-description">
              <p className="pl-50 res-work-des">
                {WorkwithusData?.description?.[0]?.work ||
                  "No description available."}
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6">
              <div className="left">
                <div className="glry-img">
                  <div className="bg-img tab-img current">
                    <img
                      src={currentTabData?.image || ""}
                      alt=""
                      className="programs-img"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 content">
              {workWithUsArray.map((item, index) => (
                <div
                  key={`tab-${item.id}`}
                  className={`mb-50 ${
                    currentTab === `tab-${item.id}` ? "current" : ""
                  }`}
                  data-tab={`tab-${item.id}`}
                  onMouseEnter={() => handleMouseEnter(`tab-${item.id}`)}
                  onClick={() => handleTabClick(index)}
                  style={{
                    visibility: animationTriggered ? "visible" : "hidden",
                    animationName: "fadeInUp",
                    animationDelay: `${index * 0.3}s`,
                    animationDuration: "3s",
                    animationFillMode: "both",
                  }}
                >
                  <div className="accordion bord">
                    <div className="item mb-15 wow fadeInUp res-work">
                      <div className="title">
                        <h6 className="text-u programs-texts">{item.name}</h6>
                        <span className="icoo">
                          {activeAccordion === index ? "×" : "+"}
                        </span>
                      </div>
                      {activeAccordion === index && (
                        <div>
                          <p className="accorion-infoo bord-thin-lefts">
                            {item.content}
                          </p>
                          <Button
                            variant="primary mt-3"
                            onClick={() => setShowForm(true)}
                            className="ml-40"
                          >
                            Apply Now
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* Modal for the Form */}
      <Modal
        show={showForm}
        onHide={() => setShowForm(false)}
        dialogClassName="custom-modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>JOIN US</Modal.Title>
        </Modal.Header>
        <Modal.Body>
         
          <form onSubmit={handleFormSubmit} className="form-container">
            <div className="form-group">
              <label htmlFor="name" style={{ marginRight: "auto" }}>
                Full Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={formValues.name}
                onChange={handleFormChange}
                className="form-control"
                placeholder="Enter Name"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email" style={{ marginRight: "auto" }}>
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formValues.email}
                onChange={handleFormChange}
                className="form-control"
                placeholder="example@gmail.com"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email" style={{ marginRight: "auto" }}>
                Contact Number
              </label>
              <input
                type="number"
                id="number"
                name="number"
                value={formValues.number}
                onChange={handleFormChange}
                className="form-control"
                placeholder="Enter Number"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="resume" style={{ marginRight: "auto" }}>
                Resume (PDF only)
              </label>
              <input
                type="file"
                id="resume"
                name="resume"
                onChange={handleFormChange}
                className="form-control"
                accept="application/pdf"
                required
              />
            </div>
            <ReCAPTCHA
      sitekey={sitekey}
      onChange={(value) => setCaptchaValue(value)}
    />
         {/* Display Error Messages */}
         {error && (
      <div className="text-danger mt-3">
        {Array.isArray(error)
          ? error.map((errMsg, index) => <div key={index}>{errMsg}</div>)
          : error}
      </div>
    )}
            <Button type="submit" variant="primary" className="submit-btn">
              Submit
            </Button>
          </form>
        </Modal.Body>
      </Modal>

      <SideNavigation
        key={menuName}
        isMenuOpen={isMenuOpen}
        handleMenuToggle={handleMenuToggle}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        defaultOpenSubMenu={menuName}
      />
    </div>
  );
}

export default WorkWithUss;
