import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import SideNavigation from "../../Menu-sidenavbar/SideNavigation ";
import { useDispatch, useSelector } from "react-redux";
import { LearningEventsActions } from "../../../Store/Learning Events/action";
import HeaderBackground from "../../../common/SubpagesBGimage";
import "@fancyapps/ui/dist/fancybox/fancybox.css"; 
import { Fancybox } from "@fancyapps/ui"; 

const SiteVistsFieldEvents = () => {
  const dispatch = useDispatch();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openKeys, setOpenKeys] = useState([]);
  const location = useLocation();

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const routeNames = {
    "/learning-events/site-visits-field-visits": {
      menuName: "Learning Events",
      subMenuName: "Site Visits & Field Visits",
    },
  };

  const { menuName, subMenuName } =
    location.state || routeNames[location.pathname] || {};
  const onOpenChange = (keys) => {
    setOpenKeys(keys);
  };

  useEffect(() => {
    dispatch({ type: LearningEventsActions.GET_SITEVISITS_FIELDVISITS });
  }, [dispatch]);

  const { SiteVisitsAndFieldVisitsData } = useSelector(
    (state) => state?.LearningEventsReducer
  );


  return (
    <div>
      <HeaderBackground
        menuName={menuName}
        subMenuName={subMenuName}
        handleMenuToggle={handleMenuToggle}
      />
      <div className="section-padding" style={{marginTop:"-35px"}}>
        <div className="container">
        {/* bord-thin-bottom */}
        <h1 className=" fw-300 heading-submenu-res core-fac-heading-res study-tour-ress overall-submenu-heading">Site Visits And Field Visits</h1>
          
          <div className="bord  d-flex align-items-center">
         
          </div>

          <div className="gallery row stand-marg student-project-wrap card-large-gap-monthly-lecture">
            {SiteVisitsAndFieldVisitsData?.map((visit) => {
              const siteImages = Object.keys(visit).filter((key)=> key.startsWith("image")&& visit[key]).map((key)=> visit[key]);
              return(
                <div className="col-lg-3 col-md-6 items d-rotate animated news-animation card-gap-res cards-responsive" key={visit.id}>
                <div class="item-box bg md-mb50 rotate-text res-text mt-50">
                     <div class="icon">
                       <img
                      onClick={(e)=>{
                        e.preventDefault();
                        if (siteImages.length>0){
                          Fancybox.show(
                            siteImages.map((img,index)=>({
                              src:img,
                              type:"image",
                                // caption: `${lecture.description} - Image ${index + 1}`,
                            }))
                          )
                        }
                      }}
                         src={visit.thumbnail}
                         alt={visit.description|| "Thumbnail"}
                         class="news-thumbnail news-thumbnail1"
                         style={{ cursor: "pointer" }}
                       />
                     </div>
                     <h5 class="mb-15 news-bot-wrap">
                       <a class="news-title">{visit.description}</a>
                     </h5>
                   </div>
                 </div>
              )
             
})}
          </div>
        </div>
      </div>
      <SideNavigation
        key={menuName}
        isMenuOpen={isMenuOpen}
        handleMenuToggle={handleMenuToggle}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        defaultOpenSubMenu={menuName}
      />
    </div>
  );
};

export default SiteVistsFieldEvents;
