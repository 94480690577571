import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import SideNavigation from "../../Menu-sidenavbar/SideNavigation ";
import HeaderBackground from "../../../common/SubpagesBGimage";
import { useDispatch, useSelector } from "react-redux";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import { ExtraCurricularActions } from "../../../Store/ExtraCurricular/action";

function ExtraCurricular() {
  const location = useLocation();
  const [activeCategory, setActiveCategory] = useState("Extra-Curricular");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openKeys, setOpenKeys] = useState([]);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  const routeNames = {           
    "/life-at-campus/extra-curricular": {
      menuName: "Life At Campus",
      subMenuName: "Extra-Curricular",
    },
  };

  const { menuName, subMenuName } =
    location.state || routeNames[location.pathname] || {};

  useEffect(() => {
    dispatch({ type: ExtraCurricularActions.GET_EXTRACURRICULAR });
  }, [dispatch]);

  const { ExtraCurricularData } = useSelector(
    (state) => state.ExtraCurricularReducer
  );

  useEffect(() => {
    if (ExtraCurricularData) {
      setIsLoading(false); // Stop loading when data is fetched
    }
  }, [ExtraCurricularData]);

  const ExtraCurricularFronts =
    ExtraCurricularData?.data?.ExtraCurricularFronts || [];

  // Extract unique categories
  // const LifeAtCampusData = [
  //   { category: "Curricular" },
  //   { category: "Co-Curricular" },
  //   { category: "Extra-Curricular" },
  //   { category: "Collaboration" },
  // ];
  
  // const uniqueCategories = Array.from(
  //   new Set(LifeAtCampusData.map((item) => item.category))
  // );

  const handleCategoryChange = (category) => {
    setActiveCategory(category);
  };

  const handleThumbnailClick = (extraCurricularId) => {
    // Filter detail images based on the clicked ID
    const detailImages = ExtraCurricularData?.data?.ExtraCurricularDetails
      .filter((item) => item.extra_curricullar_id === extraCurricularId)
      .map((item) => ({
        src: item.imagePath,
        opts: { caption: item.name || "Detail Image" },
      }));

    if (detailImages && detailImages.length > 0) {
      // Show images in Fancybox
      Fancybox.show(detailImages, {
        infinite: true,
        buttons: ["zoom", "close", "fullscreen"],
        loop: true,
      });
    }
  };

  return (
    <div>
      <main className="main-bg blog-wrap life-at-campus">
        <HeaderBackground
          menuName={menuName}
          subMenuName={subMenuName}
          handleMenuToggle={() => setIsMenuOpen(!isMenuOpen)}
        />
        <section className="work-stand section-padding sub-bg cocurricular-title">
          <div className="container">
            <div className="row">
              <div className="filtering col-12 text-center life-at-campus-filtering">
                <div className="filter">
                  {/* {uniqueCategories.map((category, index) => (
                    <span
                      key={index}
                      className={category === activeCategory ? "active" : ""}
                      onClick={() => handleCategoryChange(category)}
                    >
                      {category}
                    </span>
                  ))} */}
                     <div className="bord  d-flex align-items-center">
          <h1 className=" fw-300 heading-submenu-res core-fac-heading-res study-tour-ress overall-submenu-heading">Extra-Curricular</h1>
          
          </div>
                </div>
              </div>
            </div>
            <div className="gallery row stand-marg">
              {isLoading ? (
                <div className="loader-container">
                  <div className="spinner"></div>
                </div>
              ) : ExtraCurricularFronts.length > 0 ? (
                ExtraCurricularFronts.map((item) => (
                  <div
                    key={item.id}
                    className="col-lg-3 col-md-6 col-sm-12 mb-4"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleThumbnailClick(item.id)} // Clicking triggers the Fancybox
                  >
                    <div className="archi-card-container mt-30">
                      <div
                        className="archi-card-image-lifeatcampus pro-img"
                        style={{
                          backgroundImage: `url(${item.catagory_image})`,
                        }}
                      ></div>
                      <div className="archi-card-content">
                        <p className="archi-card-author fontWeght-lifeatcampus">
                          {item.catagory_name}
                        </p>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="loader-container">
                  <div className="spinner"></div>
                </div>
              )}
            </div>
          </div>
        </section>
      </main>
      <SideNavigation
        key={menuName}
        isMenuOpen={isMenuOpen}
        handleMenuToggle={() => setIsMenuOpen(!isMenuOpen)}
        openKeys={openKeys}
        onOpenChange={setOpenKeys}
        defaultOpenSubMenu={menuName}
      />
    </div>
  );
}

export default ExtraCurricular;
