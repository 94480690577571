import { takeEvery, call, all, put, } from "redux-saga/effects";
import { API_URL } from "../../utils/Constant";
import axios from "axios";
import { FooterActions } from "./action";

export const FooterSagas = function* () {
  yield all([takeEvery(FooterActions.GET_FOOTER_DETAILS, getFooterdata),
    takeEvery(FooterActions.GET_REPORTS_TITLE,getReportsTitle),
    takeEvery(FooterActions.GET_REPORTS_DATA,getReportsData)
  ]);

};

const getFooterdata = function* (data) {
  try {
    const result = yield call(() => axios.get(`${API_URL}/footer`));
    yield put({
      type: FooterActions.SET_FOOTER_DETAILS,
      payload: result.data,
    });
  } catch (error) {
    console.error("Error", error);
  }
};

const getReportsTitle = function* (data) {
  try {
    const result = yield call(() => axios.get(`${API_URL}/annual-report-titles`));
// console.log("annualreports",result.data);

    yield put({
      type: FooterActions.SET_REPORTS_TITLE,
      payload: result.data,
    });
  } catch (error) {
    console.error("Error", error);
  }
};
const getReportsData = function* (action) {
  try {
    const { id } = action.payload;
    // console.log("Fetching data for ID:", id);
    const result = yield call(() =>
      axios.get(`${API_URL}/annual-reports/${id}`)
    );
    // console.log("API Response for ReportsData:", result.data); 
    yield put({
      type: FooterActions.SET_REPORTS_DATA,
      payload: result.data, 
    });
  } catch (error) {
    console.error("Error fetching ReportsData:", error);
  }
};
