import React, { useState, useEffect } from "react";
import Hmenu from "../../assets/imgs/psglogo2.webp";
import Particles1 from "../../common/Particles1";
import { animated, useSpring } from "@react-spring/web";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { HomePageActions } from "../../Store/Home/action";
import { SonsandCharitiesActions } from "../../Store/PsgSonsCharities/action";
import Cursor from "../../common/Cursor";
import AnimatedCursor from "react-animated-cursor";
import { routes } from "../../routes/routes";
import { Link } from "react-router-dom";
const SideNavigation = ({
  isMenuOpen,
  handleMenuToggle,
  openKeys,
  onOpenChange,
  defaultOpenSubMenu,
}) => {
  const [clickedMenuItem, setClickedMenuItem] = useState(null);
  const [hoveredMenuItem, setHoveredMenuItem] = useState(null);
  const [menuNameMapping, setMenuNameMapping] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [charityLink, setCharityLink] = useState("");
  const location = useLocation();

  // const routes = {
  //   "Psg Iaq": "/psg-iaq",
  //   "The Trust": "/the-trust",
  //   "PSG & Sons’ Charities": charityLink,
  //   Administration: "/administration",
  //   "Statutory Committee": "/statutory-committee",
  //   "Facts & Figures": "/facts-and-figures",
  //   Principal: "/principal",
  //   "Core Faculty": "/core-faculty",
  //   "Visiting Faculty": "/visiting-faculty",
  //   "Allied Faculty": "/allied-faculty",
  //   "Administrative Staffs": "/administrative-staffs",
  //   "Expert Panel Memebers": "/expert-panel-members",
  //   "Design Chair": "/design-chair",
  //   Programs: "/programs",
  //   "Academic Timetable": "/academic-time-table",
  //   "Academic Calendar": "/academic-calendar",
  //   Syllabus: "/syllabus",
  //   Admissions: "/admissions",
  //   "Life At Campus": "/life-at-campus",
  //   "Monthly lecture series": "/monthly-lecture-series",
  //   "Study Tour": "/study-tour",
  //   "Site Visits & Field Visits": "/site-visits&field-visits",
  //   NASA: "/nasa",
  //   Symposium: "/symposium",
  //   "Connect with us": "/ConnectWithus",
  //   "IQAC": "/iqac",
  //   "Admissions":"/admissions",
  //   "Work with us":"/work-with-us",
  //   "Archigazette" :"/",
  // };

  useEffect(() => {
    if (defaultOpenSubMenu) {
      setClickedMenuItem(defaultOpenSubMenu);
    }
  }, [defaultOpenSubMenu]);

  useEffect(() => {
    if (isMenuOpen && defaultOpenSubMenu) {
      setClickedMenuItem(defaultOpenSubMenu);
    }
  }, [isMenuOpen, defaultOpenSubMenu]);

  const handleMenuItemHover = (menuItem) => {
    setHoveredMenuItem(menuItem);
  };

  const handleMenuItemLeave = () => {
    setHoveredMenuItem(null);
  };

  const handleMenuItemClick = (menuIdentifier) => {
    const menuName = menuNameMapping[menuIdentifier];
    const path = routes[menuName] || (menuName === "PSG & Sons’ Charities" ? charityLink : undefined);

    if (menuName === "Home") {
      if (location.pathname === "/") {
        handleMenuToggle();
      } else {
        navigate("/");
      }
    }

    if (clickedMenuItem === menuName) {
      setClickedMenuItem(null);
    } else {
      setClickedMenuItem(menuName);

      if (path) {
        if (location.pathname === path) {
          handleMenuToggle();
        } else {
          navigate(path, { state: { menuName } });
        }
      }
    }
  };

  const generateMenuNameMapping = (menus) => {
    const mapping = {};
    menus.forEach((item, index) => {
      mapping[`menu${index + 1}`] = item.menu.name;
    });
    return mapping;
  };

  useEffect(() => {
    dispatch({ type: SonsandCharitiesActions.GET_SONSANDCHARITIES_SECTION });
  }, [dispatch]);

  const { Charitieslink } = useSelector(
    (state) => state.SonsandCharitiesReducer
  );

  useEffect(() => {
    if (Charitieslink?.length) {
      setCharityLink(Charitieslink[0]?.link);
    }
  }, [Charitieslink]);

  const handleSubmenuItemClick = (menuIdentifier, subMenuItem) => {
    const menuName = menuNameMapping[menuIdentifier];
    setClickedMenuItem(menuName);

    const path = routes[subMenuItem];
    if (path) {
      if (location.pathname === path) {
        handleMenuToggle();
      } else {
        navigate(path, { state: { menuName, subMenuName: subMenuItem } });
      }
    }
  };

  const menuTextAnimation = useSpring({
    transform: isMenuOpen ? "translateX(0)" : "translateX(-100%)",
    opacity: isMenuOpen ? 1 : 0,
    config: { tension: 280, friction: 30 },
  });

  // const submenuAnimation = useSpring({
  //   opacity: isMenuOpen ? 1 : 0,
  //   height: isMenuOpen ? "auto" : 0,
  //   config: { duration: 200 },
  //   onRest: () => {
  //     if (!isMenuOpen) {
  //       setClickedMenuItem(null);
  //     }
  //   },
  // });

  const submenuAnimation = useSpring({
    opacity: isMenuOpen ? 1 : 0,
    height: isMenuOpen ? "auto" : 0,
    config: { tension: 220, friction: 30 },
    onRest: () => {
      if (!isMenuOpen) {
        setClickedMenuItem(null);
      }
    },
  });

  useEffect(() => {
    dispatch({ type: HomePageActions.GET_HOME_MENUS_AND_SUBMENUS });
  }, [dispatch]);

  const { MenusAndSubmenus } = useSelector((state) => state?.HomePageReducer);

  useEffect(() => {
    if (MenusAndSubmenus.length) {
      const mapping = generateMenuNameMapping(MenusAndSubmenus);
      setMenuNameMapping(mapping);
    }
  }, [MenusAndSubmenus]);
  return (
    <div id="mySidenav" className={`sidenav ${isMenuOpen ? "open" : ""}`}>
      <a className="closebtn close-menu" onClick={handleMenuToggle}>
        &times;
      </a>
      <div className="hamenu">
        <div className="">
          <AnimatedCursor
            color="128, 128, 128"
            innerSize={0}
            // outerSize={8}
            outerAlpha={0.2}
            innerScale={0.7}
            outerScale={6}
            animationDuration={0.01}
            showSystemCursor={true}
          />

          {/* <Cursor /> */}
          <Particles1 />
          <div className="row ">
            <div className="col-md-4  ">
              <div className="menu-links  hide-scroll-bar">
                <ul className="main-menu rest ">
                  <li className="ham-menu">
                    <div className="menu-logo">
                      <a href="/">
                        <img src={Hmenu} alt="Menu Logo" />
                      </a>
                    </div>
                  </li>
                  {MenusAndSubmenus
                    ?.filter(
                      (item) =>
                        item.menu.name !== "Work with us" &&
                        item.menu.name !== "Connect with us"
                    )
                    .map((item, index) => (
                      <li
                        key={index}
                        className={`menu${index + 1} ${clickedMenuItem === item.menu.name ? "open" : ""
                          }`}
                        onMouseEnter={() =>
                          handleMenuItemHover(`menu${index + 1}`)
                        }
                        onMouseLeave={handleMenuItemLeave}
                        onClick={() => handleMenuItemClick(`menu${index + 1}`)}
                      >
                        <div className="o-hidden">
                          <animated.div
                            className="link cursor-pointer dmenu"
                            style={menuTextAnimation}
                          >
                            <span
                              className="fill-text animate-charcter1"
                              data-text={item.menu.name}
                            >
                              {item.menu.name}
                            </span>
                            {item.submenus && item.submenus.length > 0 && (
                              <i className="menu-i-left"></i>
                            )}
                          </animated.div>
                        </div>
                        {/* 
                      {isMenuOpen && !hoveredMenuItem && !clickedMenuItem && (
                        <div
                          className={`image-hover-img menu-image-default img-shadow`}
                        >
                          <img
                            src={item.menu.image}
                            alt={`Menu Image ${index + 1}`}
                          />
                        </div>
                      )}
                      {hoveredMenuItem === `menu${index + 1}` && isMenuOpen && (
                        <div
                          className={`image-hover-img menu-image-${index + 1}`}
                        >
                          <img
                            src={item.menu.image}
                            alt={`Menu Image ${index + 1}`}
                          />
                        </div>
                      )} */}
                        {clickedMenuItem === item.menu.name &&
                          item.submenus &&
                          item.submenus.length > 0 && (
                            <ul className="sub-menu">
                              {item.submenus.map((submenu, subIndex) => (
                                <li key={subIndex}>
                                  {submenu === "PSG & Sons’ Charities" ? (
                                    <a
                                      className="sub-link"
                                      href="https://www.psgsonscharities.org/"
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {submenu}
                                    </a>
                                  ) : (
                                    <a
                                      className="sub-link"
                                      onClick={() =>
                                        handleSubmenuItemClick(
                                          `menu${index + 1}`,
                                          submenu
                                        )
                                      }
                                    >
                                      {submenu}
                                    </a>
                                  )}
                                </li>
                              ))}
                            </ul>
                          )}
                      </li>
                    ))}
                </ul>
              </div>
            </div>
            <div className="col-md-8 menuImages">
              {MenusAndSubmenus
                ?.filter(
                  (item) =>
                    item.menu.name !== "Work with us" &&
                    item.menu.name !== "Connect with us"
                )
                .map((item, index) => (
                  <>
                    {hoveredMenuItem === `menu${index + 1}` && isMenuOpen && (
                      <img
                        src={item.menu.image}
                      // alt={`Menu Image ${index + 1}`}
                      />)}

                    {isMenuOpen && !hoveredMenuItem && clickedMenuItem === (item.menu.name) && (
                      <div
                      // className={`image-hover-img menu-image-default img-shadow`}
                      >
                        <img
                          src={item.menu.image}
                          alt={`Menu Image ${index + 1}`}
                        />
                      </div>
                    )}


                  </>
                ))}
                
                {isMenuOpen && !hoveredMenuItem && !clickedMenuItem && (
                      <div
                        // className={`image-hover-img menu-image-default img-shadow`}
                      >
                        <img
                          // src={item.menu.image}
                        src=  {MenusAndSubmenus[MenusAndSubmenus?.length -1]?.menu?.image}
                          alt={`Menu Image`}
                        />
                      </div>
                    )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideNavigation;
