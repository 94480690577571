import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import SideNavigation from "../../Menu-sidenavbar/SideNavigation ";
import { LearningEventsActions } from "../../../Store/Learning Events/action";
import { useDispatch, useSelector } from "react-redux";
import HeaderBackground from "../../../common/SubpagesBGimage";
import "@fancyapps/ui/dist/fancybox/fancybox.css"; 

import { Fancybox } from "@fancyapps/ui"; 
const Symposium = () => {
  const dispatch = useDispatch();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openKeys, setOpenKeys] = useState([]);
  const location = useLocation();

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const routeNames = {
    "/study-tour": {
      menuName: "Learning Events",
      subMenuName: "Study Tour",
    },
  };


  const { menuName, subMenuName } =
    location.state || routeNames[location.pathname] || {};

  const onOpenChange = (keys) => {
    setOpenKeys(keys);
  };

  useEffect(() => {
    dispatch({ type: LearningEventsActions.GET_SYMPOSIUM });
  }, [dispatch]);

  const { SymposiumData } = useSelector(
    (state) => state?.LearningEventsReducer
  );

  


  return (
    <div>
      <HeaderBackground
        menuName={menuName}
        subMenuName={subMenuName}
        handleMenuToggle={handleMenuToggle}
      />
      <div className="section-padding" style={{marginTop:"-35px"}}>
        <div className="container">
          <div className="bord  d-flex align-items-center">
          <h4 className=" fw-300 ">Symposium</h4>
          
          </div>

          <div className="gallery row stand-marg student-project-wrap card-large-gap">
          {SymposiumData?.map((lecture) => {
              const lectureImages = Object.keys(lecture)
                .filter((key) => key.startsWith("image") && lecture[key])
                .map((key) => lecture[key]);

              return (
                <div
                  className="col-lg-3 col-md-6 items d-rotate animated news-animation card-gap-res"
                  key={lecture.id}
                >
                  <div className="item-box bg md-mb50 rotate-text res-text mt-50">
                    <div className="icon">
                      <img
                        onClick={(e) => {
                          e.preventDefault();
                          if (lectureImages.length > 0) {
                            Fancybox.show(
                              lectureImages.map((img, index) => ({
                                src: img,
                                type: "image",
                                // caption: `${lecture.description} - Image ${index + 1}`,
                              }))
                            );
                          }
                        }}
                        src={lecture.thumbnail}
                        alt={lecture.description || "Thumbnail"}
                        className="news-thumbnail news-thumbnail1"
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                    <h5 className="mb-15 news-bot-wrap">
                      <a className="news-title">{lecture.description}</a>
                    </h5>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <SideNavigation
        key={menuName}
        isMenuOpen={isMenuOpen}
        handleMenuToggle={handleMenuToggle}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        defaultOpenSubMenu={menuName}
      />
    </div>
  );
};

export default Symposium;
