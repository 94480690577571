import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import SideNavigation from "../../Menu-sidenavbar/SideNavigation ";
import HeaderBackground from "../../../common/SubpagesBGimage";
import { useDispatch, useSelector } from "react-redux";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import { CurricularActions } from "../../../Store/Curricular/action";

function Curricular() {
  const location = useLocation();
  const [activeCategory, setActiveCategory] = useState("Curricular");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [openKeys, setOpenKeys] = useState([]);
  const dispatch = useDispatch();

  const routeNames = {
    "/life-at-campus/curricular": {
      menuName: "Life At Campus",
      subMenuName: "Curricular",
    },
  };

  const { menuName, subMenuName } =
    location.state || routeNames[location.pathname] || {};

  useEffect(() => {
    dispatch({ type: CurricularActions.GET_CURRICULAR });
  }, [dispatch]);

  const { CurricularData } = useSelector((state) => state.CurricularReducer);

  useEffect(() => {
    if (CurricularData) {
      setIsLoading(false);
    }
  }, [CurricularData]);

  const Curriculars_FirstPage = CurricularData?.data?.Curriculars_FirstPage || [];
  const Curriculars_DetailPage = CurricularData?.data?.curriculars_DetatilPage || [];

  // const LifeAtCampusData = [
  //   { category: "Curricular" },
  //   { category: "Co-Curricular" },
  //   { category: "Extra-Curricular" },
  //   { category: "Collaboration" },
  // ];

  // const uniqueCategories = Array.from(
  //   new Set(LifeAtCampusData.map((item) => item.category))
  // );

  const handleCategoryChange = (category) => {
    setActiveCategory(category);
  };

  const handleThumbnailClick = (curricularId) => {
    const detailImages = Curriculars_DetailPage.filter(
      (item) => item.curricular_id === curricularId
    ).map((item) => ({
      src: item.image,
      opts: { caption: item.name || "Detail Image" },
    }));

    if (detailImages.length > 0) {
      Fancybox.show(detailImages, {
        infinite: true,
        buttons: ["zoom", "close", "fullscreen"],
        loop: true,
      });
    }
  };

  return (
    <div>
      <main className="main-bg blog-wrap life-at-campus">
        <HeaderBackground
          menuName={menuName}
          subMenuName={subMenuName}
          handleMenuToggle={() => setIsMenuOpen(!isMenuOpen)}
        />
        <section className="work-stand section-padding sub-bg cocurricular-title">
          <div className="container">
            <div className="row">
              <div className="filtering col-12 text-center life-at-campus-filtering">
                <div className="filter">
                  {/* {uniqueCategories.map((category, index) => (
                    <span
                      key={index}
                      className={category === activeCategory ? "active" : ""}
                      onClick={() => handleCategoryChange(category)}
                    >
                      {category}
                    </span>
                  ))} */}
                     <div className="bord  d-flex align-items-center">
          <h1 className=" fw-300 heading-submenu-res core-fac-heading-res study-tour-ress overall-submenu-heading">Curricular</h1>
          
          </div>
                </div>
              </div>
            </div>
            <div className="gallery row stand-marg">
              {isLoading ? (
                <div className="loader-container">
                  <div className="spinner"></div>
                </div>
              ) : Curriculars_FirstPage.length > 0 ? (
                Curriculars_FirstPage.map((item) => (
                  <div
                    key={item.id}
                    className="col-lg-3 col-md-6 col-sm-12 mb-4"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleThumbnailClick(item.id)}
                  >
                    <div className="archi-card-container mt-30">
                      <div
                        className="archi-card-image-lifeatcampus pro-img"
                        style={{
                          backgroundImage: `url(${item.catagory_image})`,
                        }}
                      ></div>
                      <div className="archi-card-content">
                        <p className="archi-card-author fontWeght-lifeatcampus">
                          {item.catagory_name}
                        </p>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="loader-container">
                  <div className="spinner"></div>
                </div>
              )}
            </div>
          </div>
        </section>
      </main>
      <SideNavigation
        key={menuName}
        isMenuOpen={isMenuOpen}
        handleMenuToggle={() => setIsMenuOpen(!isMenuOpen)}
        openKeys={openKeys}
        onOpenChange={setOpenKeys}
        defaultOpenSubMenu={menuName}
      />
    </div>
  );
}

export default Curricular;
