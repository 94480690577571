import React, { useState, useEffect } from "react";
import HeaderBackground from "../../../common/SubpagesBGimage";
import { useDispatch, useSelector } from "react-redux";
import { HomePageActions } from "../../../Store/Home/action";
import { EditorialActions } from "../../../Store/Editorial/action";
import { useLocation } from "react-router-dom";
import SideNavigation from "../../Menu-sidenavbar/SideNavigation ";

function formatDate(dateString) {
  const options = { year: "numeric", month: "long", day: "numeric" };
  return new Date(dateString).toLocaleDateString(undefined, options);
}

function Editorial() {
  const [activeTab, setActiveTab] = useState("tabs-1");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openKeys, setOpenKeys] = useState([]);
  const [selectedYear, setSelectedYear] = useState("All");
  const location = useLocation();
  const dispatch = useDispatch();

  const routeNames = {
    "/learning-events/editorial": {  menuName: "Learning Events", subMenuName: "Editorial" },
  };

  const { menuName, subMenuName } =
    location.state || routeNames[location.pathname] || {};




  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const onOpenChange = (keys) => {
    setOpenKeys(keys);
  };

  const { Blog } = useSelector((state) => state.HomePageReducer);
  const { editorialmenu } = useSelector((state) => state?.EditorialReducer);
  // console.log("editorialmenu", editorialmenu);

  const { editorial } = useSelector((state) => state?.EditorialReducer);
  const [selectedEditorial, setSelectedEditorial] = useState(null);
  useEffect(() => {
    dispatch({ type: HomePageActions.GET_HOME_BLOG });
    dispatch({ type: EditorialActions.GET_EDITORIALMENU_SECTIONS });
  }, [dispatch]);

  const handleTabClick = (tabId, id) => {
    setActiveTab(tabId);
    const selectedData = editorialmenu?.data?.find((item) => item.id === id);
    setSelectedEditorial(selectedData);
    dispatch({
      type: EditorialActions.GET_EDITORIALMENU_SECTIONS,
      payload: { id },
    });
  };

  const uniqueYears = Array.from(
    new Set([
      ...Blog.map((item) => new Date(item.posted_on).getFullYear()),
      ...(editorialmenu?.data?.map((item) =>
        new Date(item.posted_on).getFullYear()
      ) || []),
    ])
  ).sort((a, b) => b - a);

  const filteredBlogs =
    selectedYear === "All"
      ? Blog
      : Blog.filter(
          (item) => new Date(item.posted_on).getFullYear() === +selectedYear
        );

  const filteredEditorials =
    selectedYear === "All"
      ? editorialmenu?.data || []
      : editorialmenu?.data?.filter(
          (item) => new Date(item.posted_on).getFullYear() === +selectedYear
        ) || [];

  const sections = [
    {
      section: { section_id: 1, section_name: "Archigazette" },
      contents: filteredBlogs.map((item) => ({
        title: item.title?.slice(0, 20) + "...",
        posted_by: item.posted_by,
        posted_on: item.posted_on,
        image: item.image,
        pdf: item.pdf,
      })),
    },
    ...(editorial?.data?.map((item) => ({
      section: { section_id: item.id, section_name: item.title || "Untitled" },
      contents: filteredEditorials.map((editorialItem) => ({
        title: null,
        posted_by: editorialItem.posted_by,
        posted_on: editorialItem.posted_on,
        image: editorialItem.image,
        pdf: editorialItem.pdf,
      })),
    })) || []),
  ];
 
  return (
    <div>
     <HeaderBackground
        menuName={menuName}
        subMenuName={subMenuName}
        handleMenuToggle={handleMenuToggle}
      />
      <section className="services-tab section-padding">
        <div className="container">
          <h1 className="fw-300 core-fac-heading-res heading-submenu-res overall-submenu-heading">Editorial</h1>

          <div className="filter-section mb-4 d-flex justify-content-end">
            <label htmlFor="yearFilter" className="mr-2 align-self-center">
              Filter by Year :
            </label>
            <select
              id="yearFilter"
              value={selectedYear}
              onChange={(e) => setSelectedYear(e.target.value)}
              className="year-filter-dropdown"
            >
              <option value="All">All</option>
              {uniqueYears.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
          </div>

          <div className="row lg-marg" id="tabs">


          <div className="col-lg-12 small-screen-titles">
                      <ul className="rest">
                      {sections.map((section, index) => (
                          <li
                            key={section.section.section_id}
                            className={`item-link mb-15 go-moreee-tabs go-more-psg-iap go-more-psg-iap-line1 ${
                              activeTab === `tabs-${index + 1}` ? "current" : ""
                            }`}
                            onClick={() =>  handleTabClick( `tabs-${index + 1}`,
                              section.section.section_id)}
                          >
                            <h5>
                              <span className="fz-18 opacity-7 mr-15">{`0${
                                index} `}</span>
                                 {section.section.section_name}
                            </h5>
                          </li>
                        ))}
                      </ul>
                    </div>

                    <div className="col-lg-4 large-screen-titles">
              <div className="serv-tab-link tab-links full-width pt-40">
                <ul className="rest">
                {sections.map((section, index) => (
                    <li
                    key={section.section.section_id}
                      className={`item-link mb-15 go-moreee-tabs go-more-psg-iap go-more-psg-iap-line1 ${
                        activeTab === `tabs-${index + 1}`? "current" : ""
                      }`}
                      onClick={() =>  handleTabClick(
                        `tabs-${index + 1}`,
                        section.section.section_id
                      )}
                    >
                      <div className="u-line linee">
                        <span className="icon ti-arrow-circle-right"></span>
                        <span className="text fz-18 psg-margin"> {section.section.section_name}</span>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="col-lg-8 col-12">
              <div className="serv-tab-cont">
                {sections.map((section, index) => (
                  <div
                    key={section.section.section_id}
                    className={`tab-content ${
                      activeTab === `tabs-${index + 1}` ? "current" : ""
                    }`}
                  >
                    <div className="row">
                      {section.contents.map((content, idx) => (
                        <div
                          key={idx}
                          className="archi-card col-lg-4 col-md-6 col-sm-12 mb-4"
                          onClick={() => window.open(content.pdf)}
                          style={{ cursor: "pointer" }}
                        >
                          <div className="archi-card-container">
                            <div
                              className="archi-card-image"
                              style={{
                                backgroundImage: `url(${content.image})`,
                              }}
                            ></div>
                            <div className="archi-card-content">
                              <h6 className="archi-card-author">
                                {content.posted_by}
                              </h6>
                              <p className="archi-card-title">
                                {content.title && content.title}
                              </p>
                              <p className="archi-card-date">
                                {formatDate(content.posted_on)}
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <SideNavigation
        isMenuOpen={isMenuOpen}
        handleMenuToggle={() => setIsMenuOpen(!isMenuOpen)}
      />
    </div>
  );
}

export default Editorial;
