
import { takeEvery, call, all, put,  } from "redux-saga/effects";
import { API_URL } from "../../utils/Constant";
import axios from "axios";
import { CollaborationActions } from "./action";

export const CollaborationSagas = function* () {
  // console.log("Sagas running collaboration...");
  yield all([
    takeEvery(CollaborationActions.GET_COLLABORATION , getLifeAtCampus),
  ]);
};

const getLifeAtCampus = function* (data) {
  try {
  
    const result = yield call(() => axios.get(`${API_URL}/getCollaboration`));
  // console.log("getCollaboration",result.data);
  
    yield put({
      type: CollaborationActions.SET_COLLABORATION,
      payload: result.data,
    });
  } catch (error) {
    console.error("Error in API Call:", error);
  }
};



