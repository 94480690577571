export const routes = {
  "PSGIAP": "/about-us/psg-iap",
  "The Trust": "/about-us/the-trust",
  "PSG & Sons’ Charities": "https://www.psgsonscharities.org",
  Administration: "/about-us/administration",
  "Statutory Committee": "/about-us/statutory-committee",
  "Facts & Figures": "/about-us/facts-and-figures",
  Principal: "/people/principal",
  "Core Faculty": "/people/core-faculty",
  "Visiting Faculty": "/people/visiting-faculty",
  "Allied Faculty": "/people/allied-faculty",
  "Administrative Staffs": "/people/administrative-staffs",
  "Expert Panel Members": "/people/expert-panel-members",
  "Design Chair": "/people/design-chair",
  Programs: "/pedagogy/programs",
  "Academic Timetable": "/pedagogy/academic-time-table",
  "Academic Calendar": "/pedagogy/academic-calendar",
  Syllabus: "/pedagogy/syllabus",
  Admissions: "/admissions",
  // "Life At Campus": "/life-at-campus",
  "Monthly lecture series": "/learning-events/monthly-lecture-series",
  "Study Tour": "/learning-events/study-tour",
  "Site Visits & Field Visits": "/learning-events/site-visits-field-visits",
  NASA: "/learning-events/nasa",
  Symposium: "/learning-events/symposium",
  Editorial: "/learning-events/editorial",
  "Connect with us": "/ConnectWithus",
  IQAC: "/iqac",
  "Work with us": "/work-with-us",
  Archigazette: "/",
  ListPageNewsandEvents: "/iap-chronicles",
  Curricular: "/life-at-campus/curricular",
  "Co-Curricular": "/life-at-campus/co-curricular",
  "Extra Curricular": "/life-at-campus/extra-curricular",
  Collaboration: "/life-at-campus/collaboration",
"Exam Cell":"/pedagogy/exam-cell",
"Library":"/library",
"Labs":"/labs",
"Material museum":"/material-museum",
"Infrastructure":"/infrastructure",
"Clubs":"/learning-events/clubs",
"Archive":"/pedagogy/archive",
"Annual Reports":"/reports"
};




