import React, { useState, useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { FooterActions } from "../../Store/Footer/action";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTty } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

gsap.registerPlugin(ScrollTrigger);

const Footer = () => {
  const dispatch = useDispatch();
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 1200);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 1200);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    gsap.set(".footer-container", { yPercent: -50 });
    const uncover = gsap
      .timeline({ paused: true })
      .to(".footer-container", { yPercent: 0, ease: "none" });

    ScrollTrigger.create({
      trigger: "main",
      start: "bottom bottom",
      end: "+=50%",
      animation: uncover,
      scrub: true,
    });

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const scrollY = window.scrollY;
    gsap.to(".footer-container", { yPercent: -scrollY });
  };

  useEffect(() => {
    dispatch({ type: FooterActions.GET_FOOTER_DETAILS });
  }, [dispatch]);

  const { Footerdata } = useSelector((state) => state.FooterReducer);
console.log("Footerdata",Footerdata);

  return (
    <div className="footer-common">
      <footer
        className="clean-footer crev bg-img"
        data-background="assets/imgs/background/3.jpg"
        data-overlay-dark="10"
      >
        <div className="container pb-0 pt-70 section-padding ontop">
          <div className="row row-footer">
            <div className="col-lg-4 col-md-12">
              {Footerdata.contact &&
                Footerdata.contact.map((contact, index) => (
                  <div key={index} className="mb-10">
                    <h6 className="sub-title mb-30 footer-subtitle">Contact</h6>
                    <p className="opacity-8 footer-text">
                      <i
                        className="fa fa-map-marker mr-2"
                        style={{ color: "#ccc", fontSize: "15px" }}
                      ></i>{" "}
                      {isLargeScreen ? (
                        <>
                          {contact.address.split(" ").slice(0, 6).join(" ")}
                          <br />
                          {contact.address.split(" ").slice(6).join(" ")}
                        </>
                      ) : (
                        contact.address
                      )}
                    </p>

                    {/* <div style={{color:"#ccc" , fontSize:"15px"}}>

<a href="https://maps.app.goo.gl/H4FwyceeberZUnNa6" target="_blank" rel="noreferrer"><i className="fa fa-map-marker mr-2" style={{marginRight:"10px"}}></i>Locate Us</a> 
<br/>
</div> */}
                    {/* <div style={{ color: "#ccc", fontSize: "15px" }}>
  <i className="fa fa-globe mr-2" style={{ marginRight: "10px" }}></i>
  <a
    href="https://www.google.com/maps?q=11.064754,77.093565"
    target="_blank"
    rel="noopener noreferrer"
    style={{ color: "#ccc", textDecoration: "none" }}
  >
    DD - 11.064754, 77.093565
  </a>
</div> */}

                    <a className="opacity-8 contact-item" href={`mailto:${contact.mail}`}>
                      <FontAwesomeIcon icon={faEnvelope} />
                      <span style={{ marginLeft: "10px" }}>{contact.mail}</span>
                    </a>

                    <div className="contact-numbers">
                      <a
                        className="opacity-8 contact-item"
                        href={`tel:${contact.contact_no_1}`}
                      >
                        <FontAwesomeIcon
                          icon={faTty}
                          style={{ marginRight: "10px" }}
                        />
                        <span className="num-footer">
                          {contact.contact_no_1}
                        </span>
                      </a>

                      <div className="contact-numbers">
                        <a
                          className="opacity-8 contact-item"
                          href={`tel:${contact.contact_no_2}`}
                        >
                          <FontAwesomeIcon
                            icon={faTty}
                            style={{ marginRight: "10px" }}
                          />
                          <span className="num-footer">
                            {contact.contact_no_2}
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            <div className="col-lg-2 col-md-4 col-sm-12">
              <div className="column">
                <h6 className="sub-title mb-30 footer-subtitle">Downloads</h6>
                <ul className="rest fz-14">
                {Footerdata.downloads &&
  Footerdata.downloads.map((download, index) => {
    const isInvalidPdf =
      !download.pdf ||
      download.pdf === "https://psg.mindmadetech.in/admin/public/pdfs/" ||
      download.pdf === "https://psgiap.ac.in/test/admin/public/pdfs/";

    const link = isInvalidPdf ? download.link_name : download.pdf;
    const linkText = download.pdf_name || "Download Link";

    return (
      <li key={index} className="mb-15 opacity-8">
        <a href={link}  rel="noopener noreferrer">
          {linkText}
        </a>
      </li>
    );
  })}


                </ul>
              </div>
            </div>

            {/* Useful Links - Split into Two Columns */}
            <div className="col-lg-4 col-md-12">
              <h6 className="sub-title mb-30 footer-subtitle1  useful-link-footer">
                Useful Links
              </h6>
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <ul className="rest fz-14">
                    {Footerdata.links &&
                      Footerdata.links
                        .slice(0, Math.ceil(Footerdata.links.length / 2))
                        .map((link, index) => (
                          <li key={index} className="mb-15 opacity-8">
                            <a
                              href={link.link || link.pdf}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {link.pagename}
                            </a>
                          </li>
                        ))}
                  </ul>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <ul className="rest fz-14">
                    {Footerdata.links &&
                      Footerdata.links
                        .slice(Math.ceil(Footerdata.links.length / 2))
                        .map((link, index) => (
                          <li key={index} className="mb-15 opacity-8">
                            <a
                              href={link.link || link.pdf}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {link.pagename}
                            </a>
                          </li>
                        ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center pt-30 pb-30 mt-80 bord-thin-top">
            <div>
              <p className="fz-14">
                Copyright © {new Date().getFullYear()} PSG IAP. All rights
                reserved. Design :{" "}
                <span>
                  <a
                    href="https://mindmade.in/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    MindMade
                  </a>
                </span>
              </p>
            </div>
            <div className="ml-auto">
              <ul
                className="rest d-flex align-items-center"
                style={{ gap: "20px" }}
              >
                {Footerdata.Social_links &&
                  Footerdata.Social_links.map((social, index) => (
                    <React.Fragment key={index}>
                      {social.facebook && (
                        <li className="hover-this cursor-pointer hover-anim">
                          <motion.a
                            href={social.facebook}
                            target="_blank"
                            rel="noopener noreferrer"
                            whileHover={{ scale: 2.1 }}
                          >
                            <i className="fab fa-facebook-f" />
                          </motion.a>
                        </li>
                      )}
                      {social.linkedin && (
                        <li className="hover-this cursor-pointer">
                          <motion.a
                            href={social.linkedin}
                            target="_blank"
                            rel="noopener noreferrer"
                            whileHover={{ scale: 2.1 }}
                          >
                            <i className="fab fa-linkedin-in" />
                          </motion.a>
                        </li>
                      )}
                      {social.instagram && (
                        <li className="hover-this cursor-pointer">
                          <motion.a
                            href={social.instagram}
                            target="_blank"
                            rel="noopener noreferrer"
                            whileHover={{ scale: 2.1 }}
                          >
                            <i className="fab fa-instagram" />
                          </motion.a>
                        </li>
                      )}
                      {social.youtube && (
                        <li className="hover-this cursor-pointer hover-anim">
                          <motion.a
                            href={social.youtube}
                            target="_blank"
                            rel="noopener noreferrer"
                            whileHover={{ scale: 2.1 }}
                          >
                            <i className="fab fa-youtube" />
                          </motion.a>
                        </li>
                      )}
                      {social.twitter && (
                        <li className="hover-this cursor-pointer hover-anim">
                          <motion.a
                            href={social.twitter}
                            target="_blank"
                            rel="noopener noreferrer"
                            whileHover={{ scale: 2.1 }}
                          >
                            <img
                              src="https://upload.wikimedia.org/wikipedia/commons/5/53/X_logo_2023_original.svg"
                              alt="X logo"
                              className="x-logo"
                            />
                          </motion.a>
                        </li>
                      )}
                    </React.Fragment>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
