import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import SideNavigation from "../../Menu-sidenavbar/SideNavigation ";
import HeaderBackground from "../../../common/SubpagesBGimage";
import { AcademictimetableActions } from "../../../Store/Academic-time-table/action";

function AcademicTimetable() {
  const dispatch = useDispatch();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openKeys, setOpenKeys] = useState([]);
  const location = useLocation();

  const routeNames = {
    "/pedagogy/academic-time-table": {
      menuName: "Pedagogy",
      subMenuName: "Academic Timetable",
    },
  };

  const getCurrentAcademicYear = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const startYear =
      currentDate.getMonth() < 6 ? currentYear - 1 : currentYear;
    return `${startYear}-${startYear + 1}`;
  };

  useEffect(() => {
    const currentYear = getCurrentAcademicYear();
    dispatch({
      type: AcademictimetableActions.GET_ACADEMIC_TIMETABLE,
      payload: { year: currentYear },
    });
  }, [dispatch]);

  const generatePdfLinks = (timetable) => {
    const pdfLinks = [];
    for (let year = 1; year <= 5; year++) {
      pdfLinks.push({
        year,
        oddPdf: timetable[`year_${year}_odd_pdf`],
        evenPdf: timetable[`year_${year}_even_pdf`],
      });
    }
    return pdfLinks;
  };

  const { menuName, subMenuName } =
    location.state || routeNames[location.pathname] || {};

  const { AcademictimetableData } = useSelector(
    (state) => state?.AcademictimetableReducer
  );

  const yearLabels = [
    "1'st Year",
    "2'nd Year",
    "3'rd Year",
    "4'th Year",
    "Final Year",
  ];

  return (
    <div>
      <HeaderBackground
        menuName={menuName}
        subMenuName={subMenuName}
        handleMenuToggle={() => setIsMenuOpen(!isMenuOpen)}
      />
      <section className="section-padding">
        <div className="container">
          <h1 className="fw-300 academic-tt heading-submenu-res overall-submenu-heading">Academic Timetable</h1>
          <table className="rwd-table timetable-res mt-15">
            <thead className="desktop-only">
              <tr className="desktop-only1">
                <th className="border-right timetable-year abc">Year</th>
                {yearLabels.map((label, index) => (
                  <th key={index} className="border-right timetable-years abc">
                    {label}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {AcademictimetableData?.academic_timetable?.map((timetable) => {
                return (
                  <tr
                    key={timetable.id}
                    className="academic-timetable-row desktop-only1"
                  >
                    <td
                      data-th="Year"
                      className="academic-timetable academic-timetable-row academic-calendar-responsive def"
                    >
                      {timetable.year}
                    </td>
                    {generatePdfLinks(timetable).map((links, index) => (
                      <td
                        key={index}
                        data-th={yearLabels[index]}
                        className="pdf-links-cell res-cells def"
                      >
                        <a
                          className="hov-line timetable-sem res-sem"
                          href={links.oddPdf}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          ODD Sem
                        </a>
                        <span className="separator">, </span>
                        <a
                          className="hov-line timetable-sem res-sem"
                          href={links.evenPdf}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          EVEN Sem
                        </a>
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </section>
      <SideNavigation
        key={menuName}
        isMenuOpen={isMenuOpen}
        handleMenuToggle={() => setIsMenuOpen(!isMenuOpen)}
        openKeys={openKeys}
        onOpenChange={setOpenKeys}
        defaultOpenSubMenu={menuName}
      />
    </div>
  );
}

export default AcademicTimetable;
