import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import SideNavigation from "../../Menu-sidenavbar/SideNavigation ";

import { ExpertPanelMembersActions } from "../../../Store/expert-panel-memebers/action";
import HeaderBackground from "../../../common/SubpagesBGimage";

function ExpertPanelMembers() {
  const dispatch = useDispatch();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [hoverPosition, setHoverPosition] = useState({
    top: "50px",
    left: "50px",
  });
  const [openKeys, setOpenKeys] = useState([]);
  const location = useLocation();

  useEffect(() => {
    dispatch({
      type: ExpertPanelMembersActions.GET_EXPERTPANEL_MEMBERS_MENTORS_EXPERT_PANEL,
    });
  }, [dispatch]);

  const {  MentorsCumExpertPanel } =
    useSelector((state) => state?.ExpertPanelMembersReducer);
// console.log("MentorsCumExpertPanel",MentorsCumExpertPanel);

  const routeNames = {
    "/people/expert-panel-members": {
      menuName: "The People",
      subMenuName: "Expert Panel Members",
    },
  };

  const { menuName, subMenuName } =
    location.state || routeNames[location.pathname] || {};

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const onOpenChange = (keys) => {
    setOpenKeys(keys);
  };

  const handleMouseMove = (e) => {
    const card = e.currentTarget;
    const rect = card.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    setHoverPosition({ top: `${y}px`, left: `${x}px` });
  };

  const [showChiefAdvisorDescriptions, setShowChiefAdvisorDescriptions] =
    useState({});
  const [showMentorsDescriptions, setShowMentorsDescriptions] = useState({});

  const toggleChiefAdvisorDescription = (id) => {
    setShowChiefAdvisorDescriptions((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const toggleMentorsDescription = (id) => {
    setShowMentorsDescriptions((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const findMemberById = (id, list) => {
    return list.find((member) => member.id === Number(id));
  };

  return (
    <div>
      <HeaderBackground
        menuName={menuName}
        subMenuName={subMenuName}
        handleMenuToggle={handleMenuToggle}
      />
    
    <div className="col-12 items art">
        <div className="item mt-0">
          <div className="mt-30">
            <div className="container">
            <h1 className=" fw-300 heading-submenu-res overall-submenu-heading">Expert Panel Members</h1>
              <div className="row ">
                {MentorsCumExpertPanel.length > 0 ? (
                  MentorsCumExpertPanel.map((MentorsCumExpertPanel, index) => (
                    <div key={index} className="col-lg-3 col-md-6 col-sm-6 col-12">
                      <div className="card-2 mt-40 mb-40">
                        <div className="card-overlay-2"></div>
                        <div className="card-inner-2">
                          {MentorsCumExpertPanel.name && (
                            <h6 className="fw-700">{MentorsCumExpertPanel.name}</h6>
                          )}
                          {MentorsCumExpertPanel.designation && <p>{MentorsCumExpertPanel.designation}</p>}
                          {MentorsCumExpertPanel.committee && (
                            <p className="main-color">{MentorsCumExpertPanel.committee}</p>
                          )}
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="col-12">
                  <div className="spinner-container-admission">
        <div className="spinner-admission"></div>
      </div></div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <SideNavigation
        key={menuName}
        isMenuOpen={isMenuOpen}
        handleMenuToggle={handleMenuToggle}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        defaultOpenSubMenu={menuName}
      />
    </div>
  );
}

export default ExpertPanelMembers;
