import React, { useMemo, lazy, Suspense } from "react";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Slider = lazy(() => import("react-slick"));

function BannerVideo() {
  const selectBanner = useMemo(
    () =>
      createSelector(
        (state) => state.HomePageReducer,
        (HomePageReducer) => HomePageReducer.Banner
      ),
    []
  );

  const Banner = useSelector(selectBanner);

  const bannerFiles = useMemo(() => {
    if (!Banner || Banner.length === 0) return [];
    return Banner.map((item) => ({
      type: item.file.endsWith(".mp4") ? "video" : "image",
      url: item.file,
    }));
  }, [Banner]);

  const settings = useMemo(
    () => ({
      dots: false,
      infinite: true,
      speed: 300,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      pauseOnHover: false,
      arrows: false,
    }),
    []
  );

  return (
    <div>
      {bannerFiles.length > 0 &&
        (bannerFiles[0].type === "video" ? (
          <section className="banner-wrap">
            <div className="video-wrap">
              <div className="bg-video-wrap">
              <video
  src={bannerFiles[0].url}
  loop
  muted
  autoPlay
  preload="auto"
  playsInline
  ref={(video) => {
    if (video) video.playbackRate = 0.75; // Slows down the video
  }}
>
  Your browser does not support the video tag.
</video>

              </div>
            </div>
            <div className="banner-content">
              <div className="main-header bg-img">
                <div className="container ontop">
                  <div className="lg-text">
                    <div className="d-flex">
                      <h1 className="banner-text">
                        PSG Institute of <br />
                        Architecture and Planning
                      </h1>
                    </div>
                    <div className="d-flex">
                      <p className="white">
                        Approved by Council of Architecture New Delhi |
                        Affiliated to Anna University, Chennai
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : (
          <section className="banner-wrap">
            <div className="video-wrap">
              <div className="bg-video-wrap">
                <Suspense fallback={<div>Loading slider...</div>}>
                  <Slider {...settings}>
                    {bannerFiles.map((file, index) => (
                      <div key={index}>
                        <img
                          src={file.url}
                          alt={`Slide ${index + 1}`}
                          loading="lazy"
                        />
                      </div>
                    ))}
                  </Slider>
                </Suspense>
              </div>
            </div>
            <div className="banner-content">
              <div className="main-header bg-img">
                <div className="container ontop">
                  <div className="lg-text">
                    <div className="d-flex">
                      <h1 className="banner-text">
                        PSG Institute of <br />
                        Architecture and Planning
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ))}
    </div>
  );
}

export default BannerVideo;
