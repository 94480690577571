import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import SideNavigation from "../../Menu-sidenavbar/SideNavigation ";
import { useLocation } from "react-router-dom";
import HeaderBackground from "../../../common/SubpagesBGimage";
import AOS from "aos";
import "aos/dist/aos.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { ExamCellActions } from "../../../Store/ExamCell/action";
import { AcademictimetableActions } from "../../../Store/Academic-time-table/action";

function Archive() {
  const [activeSection, setActiveSection] = useState("Circulars");
  const [selectedYear, setSelectedYear] = useState(getPreviousYearRange());
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openKeys, setOpenKeys] = useState([]);
  const [visibleSemIndex, setVisibleSemIndex] = useState(null);
  const [sections, setSections] = useState(generateSections());
  const dispatch = useDispatch();

  const timetables = {
    "2023 - 2024": ["ODD Sem,EVEN Sem", "ODD Sem,EVEN Sem", "ODD Sem,EVEN Sem"],
    "2022 - 2023": ["ODD Sem,EVEN Sem", "ODD Sem,EVEN Sem"],
  };

  const location = useLocation();
  const routeNames = {
    "/pedagogy/archive": { menuName: "Pedagogy", subMenuName: "Archive" },
  };
  const { menuName, subMenuName } =
    location.state || routeNames[location.pathname] || {};

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const onOpenChange = (keys) => {
    setOpenKeys(keys);
  };

  useEffect(() => {
    AOS.init({ duration: 1200, once: true });

    loadYearData(selectedYear);
  }, [selectedYear]);

  const toggleSemesterVisibility = (index) => {
    setVisibleSemIndex((prev) => (prev === index ? null : index));
  };

  function getPreviousYearRange() {
    const currentYear = new Date().getFullYear();
    const previousYear = currentYear - 2;
    return `${previousYear}-${previousYear + 1}`;
  }

  function generateSections() {
    const currentYear = new Date().getFullYear();
    const previousYears = Array.from(
      { length: 5 },
      (_, index) => `${currentYear - index - 2}-${currentYear - index - 1}`
    );

    const sectionsObj = {};
    previousYears.forEach((year) => {
      sectionsObj[year] = [
        { name: `Internal Circular ${year}`, visible: false },
        { name: `Anna University Circular ${year}`, visible: false },
      ];
    });

    return sectionsObj;
  }

  const handleToggleVisibility = (index, circularType) => {
    setSections((prev) => ({
      ...prev,
      [selectedYear]: prev[selectedYear].map((sec, i) => {
        if (i === index) {
          if (circularType === "Internal") {
            return { ...sec, visible: !sec.visible };
          } else if (circularType === "Anna University") {
            return { ...sec, visible: !sec.visible };
          }
        }
        return sec;
      }),
    }));
  };

  const handleYearChange = (e) => {
    const newYear = e.target.value;
    setSelectedYear(newYear);
    loadYearData(newYear);
  };

  const loadYearData = (year) => {
    const payload = { year };

    dispatch({
      type: ExamCellActions.GET_EXAM_CELL_INTERNAL_CIRCULARS,
      payload: payload,
    });

    dispatch({
      type: ExamCellActions.GET_ANNA_UNIVERSITY_CIRCULARS,
      payload: payload,
    });

    dispatch({
      type: AcademictimetableActions.GET_ACADEMIC_TIMETABLE,
      payload: payload,
    });
  };

  const { InternalCirculars, AnnaUniversityCirculars } = useSelector(
    (state) => state.ExamCellReducer
  );

  const { AcademictimetableData } = useSelector(
    (state) => state.AcademictimetableReducer
  );

  const timetableData =
    AcademictimetableData?.academic_timetable?.find(
      (item) => item.year === selectedYear
    ) || {};

  return (
    <div>
      <main className="main-bg blog-wrap">
        <HeaderBackground
          className="admins-res"
          menuName={menuName}
          subMenuName={subMenuName}
          handleMenuToggle={handleMenuToggle}
        />
        <section className="work-minimal section-padding sub-bg administration-page">
          <div className="container-xxl">
            <div className="row admin-top">
              <div className="filtering col-12 mb-50 mt-60 text-center">
                <div>
                  <span
                    className={activeSection === "Circulars" ? "active" : ""}
                    onClick={() => setActiveSection("Circulars")}
                  >
                    Circulars
                  </span>
                  <span
                    className={activeSection === "Timetables" ? "active" : ""}
                    onClick={() => setActiveSection("Timetables")}
                  >
                    Timetables
                  </span>
                </div>

                <div className="year-filter mt-30">
                  <label>Select Year: </label>
                  <select
                    className="filter-dropdown"
                    value={selectedYear}
                    onChange={handleYearChange}
                  >
                    {Object.keys(sections).map((year, index) => (
                      <option key={index} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            <div className="gallery row stand-marg administrations archivee-tabss">
              {activeSection === "Circulars" && (
                <>
                  {/* Internal Circulars */}
                  {InternalCirculars?.data
                    ?.filter((circular) => circular.Year === selectedYear)
                    .map((circular, circularIndex) => (
                      <div
                        key={circularIndex}
                        className="col-12 items trust-info"
                      >
                        <div className="archive-container">
                          <h5
                            className="circular-header"
                            onClick={() =>
                              handleToggleVisibility(circularIndex, "Internal")
                            }
                          >
                            <span className="circular-year">
                              {circular.Year}
                            </span>
                            {circular.title || "Internal Circulars"}
                            <FontAwesomeIcon
                              icon={
                                sections[selectedYear][circularIndex]?.visible
                                  ? faChevronUp
                                  : faChevronDown
                              }
                              className="toggle-icon"
                            />
                          </h5>

                          {sections[selectedYear][circularIndex]?.visible && (
                            <div className="container circular-content">
                              {circular.semesters.map((semester, semIndex) => (
                                <div
                                  className="panel panel-default"
                                  key={semIndex}
                                >
                                  <div className="panel-heading">
                                    <h4 className="panel-title">
                                      <div
                                      style={{height:" 35px" , marginTop:"15px"}}
                                      role="button" data-toggle="collapse" data-parent="#accordion" aria-expanded="false" aria-controls="collapse-0" class=""
                                        onClick={() =>
                                          toggleSemesterVisibility(semIndex)
                                        }
                                        className="more-less-toggle"
                                      >
                                        <i
                                        
                                          className={`more-less fa ${
                                            visibleSemIndex === semIndex
                                              ? "fa-angle-up"
                                              : "fa-angle-down"
                                          }`}
                                          style={{color: "white",marginTop:"7px"}}
                                        ></i>
                                        &nbsp;
                                        <strong>{semester.semester}</strong>
                                      </div>
                                    </h4>
                                  </div>

                                  {visibleSemIndex === semIndex && (
                                    <div className="panel-body">
                                      <div className="collapse-inner">
                                        {semester.internals.map(
                                          (internal, index) => (
                                            <a
                                              key={index}
                                              className="btn btn-link circular-link"
                                              href={internal.pdf}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              style={{ color: "black" }}
                                            >
                                              {internal.name}
                                            </a>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    ))}

                  {/* If no data for Internal Circulars */}
                  {!InternalCirculars?.data?.some(
                    (circular) => circular.Year === selectedYear
                  ) && (
                    <div className="no-data-message">
                      <p>
                        No data available for the selected year in Internal
                        Circulars.
                      </p>
                    </div>
                  )}

                  {/* Anna University Circulars */}
                  {AnnaUniversityCirculars?.data
                    ?.filter((circular) => circular.year === selectedYear)
                    .map((circular, ANcircularIndex) => (
                      <div
                        key={ANcircularIndex}
                        className="col-12 items trust-info"
                      >
                        <div className="archive-container">
                          <h5
                            className="circular-header"
                            onClick={() =>
                              handleToggleVisibility(
                                ANcircularIndex,
                                "Anna University"
                              )
                            }
                          >
                            <span className="circular-year">
                              {circular.year}
                            </span>
                            {circular.title || "Anna University Circulars"}
                            <FontAwesomeIcon
                              icon={
                                sections[selectedYear][ANcircularIndex]?.visible
                                  ? faChevronUp
                                  : faChevronDown
                              }
                              className="toggle-icon"
                            />
                          </h5>

                          {sections[selectedYear][ANcircularIndex]?.visible && (
                            <div className="container circular-content">
                              {circular.circulars.map(
                                (annacircular, annacircularIndex) => (
                                  <div key={annacircularIndex}>
                                    <a
                                      className="btn btn-link circular-link"
                                      href={
                                        annacircular[
                                          "circular_pdf_" +
                                            (annacircularIndex + 1)
                                        ]
                                      }
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      style={{ color: "black" }}
                                    >
                                      {
                                        annacircular[
                                          "circular_title_" +
                                            (annacircularIndex + 1)
                                        ]
                                      }
                                    </a>
                                  </div>
                                )
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    ))}

             
                  {!AnnaUniversityCirculars?.data?.some(
                    (circular) => circular.year === selectedYear
                  ) && (
                    <div className="no-data-message">
                      <p>
                        No data available for the selected year in Anna
                        University Circulars.
                      </p>
                    </div>
                  )}
                </>
              )}

{activeSection === "Timetables" && (
  <section className="section-padding">
    <div className="container">
      {/* <h4 className="fw-300 academic-tt">Academic Timetable</h4> */}
      <table className="rwd-table timetable-res mt-15">
        <thead className="desktop-only">
          <tr className="desktop-only1">
            <th className="border-right timetable-year abc">Year</th>
            <th className="border-right timetable-years abc">1'st Year</th>
            <th className="border-right timetable-years abc">2'nd Year</th>
            <th className="border-right timetable-years abc">3'rd Year</th>
            <th className="border-right timetable-years abc">4'th Year</th>
            <th className="border-right timetable-years abc">Final Year</th>
          </tr>
        </thead>
        <tbody>
          <tr className="academic-timetable-row desktop-only1">
            <td
              data-th="Year"
              className="academic-timetable academic-timetable-row academic-calendar-responsive def"
            >
              {selectedYear}
            </td>
            {[
              { odd: "year_1_odd_pdf", even: "year_1_even_pdf" },
              { odd: "year_2_odd_pdf", even: "year_2_even_pdf" },
              { odd: "year_3_odd_pdf", even: "year_3_even_pdf" },
              { odd: "year_4_odd_pdf", even: "year_4_even_pdf" },
              { odd: "year_5_odd_pdf", even: "year_5_even_pdf" },
            ].map((year, index) => (
              <td
                key={index}
                data-th={`${index + 1}'st Year`}
                className="pdf-links-cell res-cells def"
              >
                {timetableData[year.odd] || timetableData[year.even] ? (
                  <>
                    {timetableData[year.odd] && (
                      <a
                        className="hov-line timetable-sem res-sem"
                        href={timetableData[year.odd]}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        ODD Sem
                      </a>
                    )}
                    {timetableData[year.even] && timetableData[year.odd] && (
                      <span className="separator">, </span>
                    )}
                    {timetableData[year.even] && (
                      <a
                        className="hov-line timetable-sem res-sem"
                        href={timetableData[year.even]}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        EVEN Sem
                      </a>
                    )}
                  </>
                ) : (
                  <span className="no-data">N/A</span>
                )}
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  </section>
)}

            </div>
          </div>
        </section>
      </main>
      <SideNavigation
        key={menuName}
        isMenuOpen={isMenuOpen}
        handleMenuToggle={() => setIsMenuOpen(!isMenuOpen)}
        openKeys={openKeys}
        onOpenChange={setOpenKeys}
        defaultOpenSubMenu={menuName}
      />
    </div>
  );
}

export default Archive;
