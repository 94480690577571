import { FactsandFiguresActions } from "./action";
import { takeEvery, call, all, put, } from "redux-saga/effects";
import { API_URL } from "../../utils/Constant";
import axios from "axios";

export const FactsandFiguresSagas = function* () {
  yield all([
    takeEvery(
      FactsandFiguresActions.GET_FACTSANDFIGURES_SECTIONS,
      getFactsandFiguresSections
    ),
  ]);
};

const getFactsandFiguresSections = function* (data) {
  // console.log("factsss");
  
  try {
    const result = yield call(() => axios.get(`${API_URL}/FactsandFigures`));
      //  console.log("api",result.data);
       
    yield put({
      type: FactsandFiguresActions.SET_FACTSANDFIGURES_SECTIONS,
      payload: result.data,
    });
  } catch (error) {
    console.error("Error", error);
  }
};
