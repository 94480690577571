import { ExpertPanelMembersActions } from "./action";
import { takeEvery, call, all, put } from "redux-saga/effects";
import { API_URL } from "../../utils/Constant";
import axios from "axios";

export const ExpertPanelMembersSagas = function* () {
  yield all([
    takeEvery(
      ExpertPanelMembersActions.GET_EXPERTPANEL_MEMBERS_CHIEF_ADVISOR,
      getExpertPanelMemberCheifAdvisor
    ),
    takeEvery(
      ExpertPanelMembersActions.GET_EXPERTPANEL_MEMBERS_MENTORS_EXPERT_PANEL,
      getExpertPanelMemberMentorsExpertPanel
    ),
  ]);
};

const getExpertPanelMemberCheifAdvisor = function* (data) {
  try {
    const result = yield call(() => axios.get(`${API_URL}/chief-advisor`));

    yield put({
      type: ExpertPanelMembersActions.SET_EXPERTPANEL_MEMBERS_CHIEF_ADVISOR,
      payload: result.data,
    });
  } catch (error) {
    console.error("Error", error);
  }
};

const getExpertPanelMemberMentorsExpertPanel = function* (data) {
  try {
    const result = yield call(() => axios.get(`${API_URL}/expert-member`));
  //  console.log("expert-member",result);
   
    yield put({
      type: ExpertPanelMembersActions.SET_EXPERTPANEL_MEMBERS_MENTORS_EXPERT_PANEL,
      payload: result.data,
    });
  } catch (error) {
    console.error("Error", error);
  }
};
