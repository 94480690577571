import { all, call, put, takeEvery } from "redux-saga/effects";
import { ExamCellActions } from "./action";
import axios from "axios";
import { API_URL } from "../../utils/Constant";

export const ExamCellSagas = function* () {
  yield all([
    takeEvery(ExamCellActions.GET_EXAM_CELL, getExamCell),

    takeEvery(ExamCellActions.GET_EXAM_CELL_ABOUT, getExamCellAbout),
    takeEvery(ExamCellActions.GET_EXAM_CELL_UNIRESULT, getExamCellUniRes),
    takeEvery(
      ExamCellActions.GET_EXAM_CELL_USEFUL_LINKS,
      getExamCellUsefulLinks
    ),
    takeEvery(ExamCellActions.GET_EXAM_CELL_CONTACTUS, getExamCellContactUs),
    takeEvery(
      ExamCellActions.GET_EXAM_CELL_INTERNAL_CIRCULARS,
      getExamCellInternalCirculars
    ),
    takeEvery(
      ExamCellActions.GET_ANNA_UNIVERSITY_CIRCULARS,
      getAnnaUniversityCirculars
    ),
  ]);
};

const getExamCell = function* (data) {
  try {
    const result = yield call(() => axios.get(`${API_URL}/examcell-people`));

    yield put({
      type: ExamCellActions.SET_EXAM_CELL,
      payload: result.data,
    });
  } catch (error) {
    console.error("Error", error);
  }
};

const getExamCellAbout = function* (data) {
  try {
    const result = yield call(() => axios.get(`${API_URL}/examcell-about`));
    // console.log("about", result);
    //  console.log("people", result);

    yield put({
      type: ExamCellActions.SET_EXAM_CELL_ABOUT,
      payload: result.data,
    });
  } catch (error) {
    console.error("Error", error);
  }
};

const getExamCellUniRes = function* (data) {
  try {
    const result = yield call(() => axios.get(`${API_URL}/universities`));
    // console.log("uni", result);

    yield put({
      type: ExamCellActions.SET_EXAM_CELL_UNIRESULT,
      payload: result.data,
    });
  } catch (error) {
    // console.error("Error", error);
  }
};

const getExamCellUsefulLinks = function* (data) {
  try {
    const result = yield call(() => axios.get(`${API_URL}/UsefullLinks`));
    // console.log("links", result);

    yield put({
      type: ExamCellActions.SET_EXAM_CELL_USEFUL_LINKS,
      payload: result.data,
    });
  } catch (error) {
    console.error("Error", error);
  }
};
const getExamCellContactUs = function* (data) {
  try {
    const result = yield call(() => axios.get(`${API_URL}/ContactUsData`));
    // console.log("contactus", result);

    yield put({
      type: ExamCellActions.SET_EXAM_CELL_CONTACTUS,
      payload: result.data,
    });
  } catch (error) {
    console.error("Error", error);
  }
};

const getExamCellInternalCirculars = function* (action) {
  try {
    const year = action.payload?.year;

    if (!year) {
      console.error("Error: Year is missing in payload");
      return;
    }

    const result = yield call(() =>
      axios.get(`${API_URL}/internal-circulars/${year}`)
    );

    yield put({
      type: ExamCellActions.SET_EXAM_CELL_INTERNAL_CIRCULARS,
      payload: result.data,
    });
  } catch (error) {
    console.error("Error fetching circulars:", error.response || error);
  }
};

const getAnnaUniversityCirculars = function* (action) {
  try {
    // console.log("Saga received action:", action);

    const year = action.payload?.year;

    if (!year) {
      console.error("Error: Year is missing in payload");
      return;
    }

    // console.log(
    //   "Saga making API call to:",
    //   `${API_URL}/anna-university/${year}`
    // );

    const result = yield call(() =>
      axios.get(`${API_URL}/anna-university/${year}`)
    );

    // console.log("API Response AU:", result.data);

    yield put({
      type: ExamCellActions.SET_ANNA_UNIVERSITY_CIRCULARS,
      payload: result.data,
    });
  } catch (error) {
    console.error("Error fetching circulars:", error.response || error);
  }
};
