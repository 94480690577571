import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HomePageActions } from "../../Store/Home/action";
import { Link } from "react-router-dom";

function BlogListCrev() {
  const dispatch = useDispatch();

  // Fetch blogs when the component mounts
  useEffect(() => {
    dispatch({ type: HomePageActions.GET_HOME_BLOG });
  }, [dispatch]);

  // Get blogs and headings from the Redux store
  const { Blog, blogHeadings } = useSelector((state) => state.HomePageReducer);
  // console.log("Blog", Blog);
  // console.log("blogHeadings", blogHeadings);

  const [animateHeading, setAnimateHeading] = useState(false);
  const headingRef = useRef(null);

  // Intersection Observer for heading animation
  useEffect(() => {
    const targetElement = headingRef.current;

    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setAnimateHeading(true);
        }
      });
    }, options);

    if (targetElement) {
      observer.observe(targetElement);
    }

    return () => {
      if (targetElement) {
        observer.unobserve(targetElement);
      }
    };
  }, []);

  const formatDate = (dateString) => {
    // Check if dateString is valid
    if (!dateString) {
      console.warn("Invalid dateString:", dateString);
      return "Invalid Date";
    }
  
    try {
      const [year, month, day] = dateString.split("-");
      return `${day}-${month}-${year}`;
    } catch (error) {
      console.error("Error formatting date:", error);
      return "Invalid Date";
    }
  };
  
  
  return (
    <section className="blog-list-crev home-pg-common-mt home-pg-common-mt-blog">
      <div className="container blog-fin">
        <div className="sec-head mb-80">
          <div className="pt-25 d-flex align-items-center">
            <h2
              ref={headingRef}
              className={`fw-600 d-rotate wow ${
                animateHeading ? "heading-animation" : ""
              }`}
            >
              <span className="rotate-text blog-text princi home-heading-text-blog">
                {blogHeadings?.[0]?.heading || "EDITORIAL ARCHIGAZETTE"}
              </span>
            </h2>
          </div>
        </div>

        {/* Blog items */}
        {Blog.slice(0, 3).map((item, index) => {
          const isEven = index % 2 === 0; // Alternate background logic
          return (
            <div
              key={item.id}
              className={`item-wrapper ${animateHeading ? "heading-animation res-blog-box" : ""} ${
                isEven ? "sub-bg2" : ""
              }`}
            >
              <div className="item wow fadeInUp" data-wow-delay=".1s">
                <div className="row">
                  <div className="col-lg-3">
                    <div className="info md-mb30">
                      {/* Author info, if needed */}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="cont">
                      <h5 className="mb-10 blo-text-na">
                        <Link to={`/blog/${item.id}`}>{item.title}</Link>
                      </h5>
                    </div>
                  </div>
                  <div className="col-lg-3 d-flex align-items-center justify-end">
                    <div className="ml-auto">
                      <span className="date sub-title fz-13 opacity-8 mb-30">
                        {formatDate(item.posted_on)}
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  className="background bg-img valign text-center"
                  style={{ backgroundImage: `url(${item.image})` }}
                  data-overlay-dark="4"
                >
                  <div className="more ontop full-width">
                    <a href={item.pdf} target="_blank" rel="noopener noreferrer">
                      <span>
                        Read More <i className="fas fa-arrow-right ml-10"></i>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          );
        })}

        {/* View More Button */}
        <div className="flr mt-30">
          <Link to="/learning-events/editorial" className="go-more">
            <span className="text">View more </span>
            <span className="icon ti-arrow-circle-right"></span>
          </Link>
        </div>
      </div>
    </section>
  );
}

export default BlogListCrev;
