import { takeEvery, call, all, put } from "redux-saga/effects"
import { ClubsActions } from "./action"
import axios from "axios"
import { API_URL } from "../../utils/Constant"

export const ClubsSagas = function* (){
    yield all([
        takeEvery(ClubsActions.GET_CLUBS,getClubs),
        takeEvery(ClubsActions.GET_CLUBS_DETAIL,getClubsDetails)
    ])
}

const getClubs = function*(data){
    try{
        const result = yield call(()=>axios.get(`${API_URL}/club-headings`));
        // console.log("club-headings",result.data);
        // 
        yield put({
            type:ClubsActions.SET_CLUBS,
            payload:result.data,
        });

    }catch(error){
        console.error("error",error);
        
        
    }
}

const getClubsDetails = function* (action) {
    try {
      const { id } = action.payload;
      // console.log("Fetching details for club ID:", id); // Log the ID being fetched
      const result = yield call(() => axios.get(`${API_URL}/clubs/${id}`));
      // console.log("Club details response:", result.data); // Log the API response
  
      yield put({
        type: ClubsActions.SET_CLUBS_DETAIL,
        payload: result.data,
      });
    } catch (error) {
      console.error("Error fetching club details:", error);
    }
  };




  