import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AdmissionsActions } from "../../Store/Admissions/action";
import SideNavigation from "../Menu-sidenavbar/SideNavigation ";
import HeaderBackground from "../../common/SubpagesBGimage";

function Admissions() {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("");
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openKeys, setOpenKeys] = useState([]);
  const [tabs, setTabs] = useState([]);
  const routeNames = {
    "/admissions": {
      menuName: "Admissions",
    },
  };

  const { menuName } = location.state || routeNames[location.pathname] || {};

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const onOpenChange = (keys) => {
    setOpenKeys(keys);
  };

  useEffect(() => {
    dispatch({ type: AdmissionsActions.GET_ADMISSIONS });
  }, [dispatch]);

  useEffect(() => {
    dispatch({ type: AdmissionsActions.GET_ADMISSIONS_DETAIL });
  }, [dispatch]);

  const { AdmissionsData } = useSelector((state) => state?.AdmissionsReducer);
  const { AdmissionDataDetail } = useSelector(
    (state) => state?.AdmissionsReducer
  );
  // console.log("AdmissionsData", AdmissionsData);
  // console.log("AdmissionDataDetail", AdmissionDataDetail);

  useEffect(() => {
    if (AdmissionsData?.length) {
      const apiTabs = AdmissionsData.map((item, index) => ({
        id: `tabs-${index + 1}`,
        name: item.name,
        itemId: item.id,
      }));

      // console.log("Mapped Tabs:", apiTabs);

      setTabs(apiTabs);

      if (apiTabs.length > 0) {
        setActiveTab(apiTabs[0]?.id || "");
        dispatch({
          type: AdmissionsActions.GET_ADMISSIONS_DETAIL,
          payload: { id: apiTabs[0]?.itemId },
        });
      }
    }
  }, [AdmissionsData, dispatch]);

  const handleTabClick = (tabId, itemId) => {
    // console.log("Tab Clicked - tabId:", tabId, "itemId:", itemId);
    setActiveTab(tabId);
    dispatch({
      type: AdmissionsActions.GET_ADMISSIONS_DETAIL,
      payload: { id: itemId },
    });
  };

  const renderContent = () => {
    const currentTab = tabs.find((tab) => tab.id === activeTab);

    if (currentTab) {
      const details = AdmissionDataDetail?.admissions?.filter(
        (item) => parseInt(item.section_id) === currentTab.itemId
      );

      if (details?.length > 0) {
        return (
          <div className="tab-content current" id={activeTab}>
            <div className="item">
              <div className="sub-bg">
                <div className="text">
                  <h6 className="Components-title">{currentTab.name}</h6>
                  <div
                    className="rest list-arrow mt-30"
                    style={{ color: "black" }}
                  >
                    {details.map((detail) => {
                      // console.log("Detail Content:", detail.content);
                      // Dynamically extract all description-pdf pairs
                      const descriptionKeys = Object.keys(detail).filter(
                        (key) => key.startsWith("description")
                      );
                      const pdfKeys = Object.keys(detail).filter((key) =>
                        key.startsWith("pdf")
                      );

                      return (
                        <div key={detail.id} style={{ marginBottom: "20px" }} >
                          <div
                          className="admissions-font-sizee"
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "10px",
                            }}
                          >
                            {descriptionKeys.map((descKey, index) => {
                              const pdfKey = pdfKeys[index]; // Match the description with its corresponding PDF
                              return (
                                detail[descKey] && (
                                  <div key={descKey}>
                                    {detail[pdfKey] ? (
                                      <a
                                        href={detail[pdfKey]}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-decoration-none description-link admission-ress-view"
                                        style={{ display: "block" }}
                                      >
                                        {detail[descKey]}
                                      </a>
                                    ) : (
                                      <span className="description-text">
                                        {detail[descKey]}
                                      </span> // Display text if no PDF
                                    )}
                                  </div>
                                )
                              );
                            })}
                          </div>

                          {/* Render additional content, if available */}
                          {detail.content && (
                            <p
                              className="admission-li  admission-ress-view admission-p-size"
                              style={{ marginTop: "10px" }}
                              dangerouslySetInnerHTML={{
                                __html: detail.content,
                              }}
                            ></p>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
    }

    // Show spinner if data is still loading
    return (
      <div className="spinner-container-admission">
        <div className="spinner-admission"></div>
      </div>
    );
  };

  return (
    <div>
      <HeaderBackground
        menuName={menuName}
        handleMenuToggle={handleMenuToggle}
      />
      <section className="services-tab section-padding mb-80">
        <div className="container ">
          <div className="cal-action pb-10 mb-40 ">
            <h1 className=" fw-300 core-fac-heading-res heading-submenu-res overall-submenu-heading">
              Admissions
            </h1>
          </div>
          <div className="row lg-marg" id="tabs">
            <div className="d-lg-none col-12">
              <div className="serv-tab-link tab-links full-width pt-40">
                <div className="row justify-content-end admission-tabs">
                  <div className="col-lg-12 small-screen-titles ">
                    <ul className="rest">
                      {tabs.map((tab, index) => (
                        <li
                          key={tab.id}
                          className={`item-link mb-15 go-moreee-tabs go-more-psg-iap go-more-psg-iap-line1 ${
                            activeTab === tab.id ? "current" : ""
                          }`}
                          onClick={() => handleTabClick(tab.id, tab.itemId)}
                        >
                          <h5>
                            <span className="fz-18 opacity-7 mr-15">{`0${index}`}</span>
                            {tab.name}
                          </h5>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 d-none d-lg-block">
              <div className="serv-tab-link tab-links full-width pt-40">
                <div className="row justify-content-end admission-tabs">
                  <div className="col-lg-12">
                    <ul className="rest">
                      {tabs.map((tab) => (
                        <li
                          key={tab.id}
                          className={`item-link mb-15 go-moreee-tabs go-more-psg-iap go-more-psg-iap-line1 ${
                            activeTab === tab.id ? "current" : ""
                          }`}
                          onClick={() => handleTabClick(tab.id, tab.itemId)}
                        >
                          <div className="u-line linee">
                            <span className="icon ti-arrow-circle-right"></span>
                            <span className="text fz-20 psg-margin">
                              {tab.name}
                            </span>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-8 col-12 ">
              <div className="md-mb80 admission-ress-view">
                {renderContent()}
              </div>
            </div>
          </div>
        </div>
      </section>

      <SideNavigation
        key={menuName}
        isMenuOpen={isMenuOpen}
        handleMenuToggle={handleMenuToggle}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        defaultOpenSubMenu={menuName}
      />
    </div>
  );
}

export default Admissions;




