import React, { useRef, useState, useEffect, useMemo } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useDispatch, useSelector } from "react-redux";
import { HomePageActions } from "../../Store/Home/action";
import ReactPlayer from "react-player";

function VideoSection() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: HomePageActions.GET_HOME_LATESTVIDEO });
  }, [dispatch]);

  const { LatestVideo, vidHeadings } = useSelector(
    (state) => state.HomePageReducer
  );
  // console.log("vidHeadings",vidHeadings);
  // console.log("LatestVideo",LatestVideo);
  
  
  // console.log("LatestVideo",LatestVideo)
  const [showPopup, setShowPopup] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const sliderRef = useRef(null);

  const openPopup = (url) => {
    setVideoUrl(url);
    setShowPopup(true);
  };

  const closePopup = (e) => {
    if (e.target.classList.contains("video-popup-videosection")) {
      setVideoUrl("");
      setShowPopup(false);
    }
  };

  const nextSlide = () => sliderRef.current?.slickNext();
  const prevSlide = () => sliderRef.current?.slickPrev();

  const memoizedSettings = React.useMemo(
    () => ({
      dots: false,
      infinite: true,
      speed: 500,
      autoplay: false,
      autoplaySpeed: 3000,
      slidesToShow: 3,
      slidesToScroll: 1,
      lazyLoad: "ondemand",
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 1,
            infinite: true,
            dots: false,
          },
        },
        {
          breakpoint: 640,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
            infinite: true,
            dots: false,
          },
        },
        {
          breakpoint: 0,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
            infinite: true,
            dots: false,
          },
        },
      ],
    }),
    []
  );

  return (
    <section className="services home-pg-common-mt sub-bg sec-pad-vid">
      <div className="container">
        <div className="sec-head mb-30">
          {/* <h6 className="sub-title main-color mb-25 home-gap">
            Watch our videos
          </h6> */}
          <div className=" pt-25  d-flex align-items-center">
            <h2 className="fw-600 ls1 iap-docutainment">
            {vidHeadings && vidHeadings.length > 0 ? vidHeadings[0].heading : ""}
            </h2>
            <div className="ml-auto">
              <div className="swiper-arrow-control">
                <button className="swiper-button-prev" onClick={prevSlide}>
                  <span className="ti-arrow-circle-left vid-arrow"></span>
                </button>
                <button className="swiper-button-next" onClick={nextSlide}>
                  <span className="ti-arrow-circle-right vid-arrow"></span>
                </button>
              </div>
            </div>
          </div>
        </div>

        {Array.isArray(LatestVideo) && LatestVideo.length > 0 ? (
          <Slider {...memoizedSettings} ref={sliderRef}>
            {LatestVideo.map((item, index) => (
              <div className="swiper-wrapper" key={index}>
                <div className="item-box radius-15 res-vid">
                  <div
                    className="vid-con"
                    onClick={() => openPopup(item.link2)}
                    style={{ position: "relative" }}
                  >
                    <img
                      src={item.image2_url
                      }
                      alt="Video Thumbnail"
                      className="video-thumbnail"
                      style={{
                        width: "100%",
                        height: "200px",
                        objectFit: "cover",
                        cursor: "pointer",
                      }}
                    />
                    <button
                      className="play-button"
                      onClick={() => openPopup(item.link2)}
                    >
                      <i className="play-icon"></i>
                    </button>
                  </div>

                  <h5 className="text-videosection">
                    {item.title.slice(0, 35)}...
                  </h5>
                  <p className="video-texts">
                    {item.description.slice(0, 100)}...
                  </p>
                </div>
              </div>
            ))}
          </Slider>
        ) : (
          <p>No videos available at the moment.</p>
        )}

        {showPopup && (
          <div className="close-btn-videosection" onClick={closePopup}>
            <i className="fas fa-times"></i>
          </div>
        )}

        {showPopup && (
          <>
            <div
              className="close-btn-videosection"
              onClick={() => setShowPopup(false)}
            >
              <i className="fas fa-times"></i>
            </div>
            <div className="video-popup-videosection" onClick={closePopup}>
              <div className="video-container-videosection">
                <div className="video-content-videosection">
                  <ReactPlayer
                    url={videoUrl}
                    width="100%"
                    height="100%"
                    className="react-player"
                    controls={true}
                    playing={true}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </section>
  );
}

export default VideoSection;
